@charset "UTF-8";
@font-face {
  font-family: 'CCKillJoy';
  font-style: normal;
  font-weight: normal;
  src: url("fonts/CCKillJoy-Regular.ttf") format("truetype"), url("fonts/CCKillJoy-Regular.otf") format("opentype"), url("fonts/CCKillJoy-Regular.woff") format("woff"); }

@font-face {
  font-family: 'Arial Black';
  font-style: normal;
  font-weight: 900; }

[tabindex="-1"] {
  outline: 0; }

button,
input,
select,
textarea {
  font-family: Arial;
  font-size: 24px;
  font-weight: normal;
  line-height: 32px;
  appearance: none;
  background: none;
  border: 0;
  border-radius: 0;
  color: #231f20;
  display: block;
  -webkit-font-smoothing: antialiased;
  padding: 0; }

html,
body {
  -webkit-font-smoothing: antialiased;
  min-height: 100%; }

a,
abbr {
  text-decoration: none; }

body {
  font-family: Arial;
  font-size: 24px;
  font-weight: normal;
  line-height: 32px;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  /*
    Places the navigation on top of the banner

    Scalajs wraps each page in an a div before injecting it on the page. Some patterns need to targets as a child
    of the scalajs payload div
  */ }
  body #scalajs-app > div > nav {
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2; }

main {
  display: block;
  min-height: 100%; }

button {
  cursor: pointer; }

@page {
  size: 5.5in 8.5in;
  margin: 0px; }

/* generated with grunt-sass-globbing */
.u-block {
  display: block; }

.u-hidden-maxtablet:empty + .u-hidden-peekaboo::before {
  content: '🙉'; }

@media (max-width: 767px) {
  .u-hidden-maxtablet {
    display: none; }
    .u-hidden-maxtablet:empty + .u-hidden-peekaboo::before {
      content: '🙈'; } }

.u-hidden-mintablet:empty + .u-hidden-peekaboo::before {
  content: '🙉'; }

@media (min-width: 768px) {
  .u-hidden-mintablet {
    display: none; }
    .u-hidden-mintablet:empty + .u-hidden-peekaboo::before {
      content: '🙈'; } }

.u-hidden-maxlaptop:empty + .u-hidden-peekaboo::before {
  content: '🙉'; }

@media (max-width: 1023px) {
  .u-hidden-maxlaptop {
    display: none; }
    .u-hidden-maxlaptop:empty + .u-hidden-peekaboo::before {
      content: '🙈'; } }

.u-hidden-minlaptop:empty + .u-hidden-peekaboo::before {
  content: '🙉'; }

@media (min-width: 1024px) {
  .u-hidden-minlaptop {
    display: none; }
    .u-hidden-minlaptop:empty + .u-hidden-peekaboo::before {
      content: '🙈'; } }

.u-hidden-maxnavigation:empty + .u-hidden-peekaboo::before {
  content: '🙉'; }

@media (max-width: 804px) {
  .u-hidden-maxnavigation {
    display: none; }
    .u-hidden-maxnavigation:empty + .u-hidden-peekaboo::before {
      content: '🙈'; } }

.u-hidden-minnavigation:empty + .u-hidden-peekaboo::before {
  content: '🙉'; }

@media (min-width: 805px) {
  .u-hidden-minnavigation {
    display: none; }
    .u-hidden-minnavigation:empty + .u-hidden-peekaboo::before {
      content: '🙈'; } }

.u-hidden-maxhd:empty + .u-hidden-peekaboo::before {
  content: '🙉'; }

@media (max-width: 1439px) {
  .u-hidden-maxhd {
    display: none; }
    .u-hidden-maxhd:empty + .u-hidden-peekaboo::before {
      content: '🙈'; } }

.u-hidden-minhd:empty + .u-hidden-peekaboo::before {
  content: '🙉'; }

@media (min-width: 1440px) {
  .u-hidden-minhd {
    display: none; }
    .u-hidden-minhd:empty + .u-hidden-peekaboo::before {
      content: '🙈'; } }

.u-margin-all0p5x {
  margin: 4px; }

.u-margin-all1x {
  margin: 8px; }

.u-margin-all1p5x {
  margin: 12px; }

.u-margin-all2x {
  margin: 16px; }

.u-margin-all2p5x {
  margin: 20px; }

.u-margin-all3x {
  margin: 24px; }

.u-margin-all3p5x {
  margin: 28px; }

.u-margin-all4x {
  margin: 32px; }

.u-margin-all4p5x {
  margin: 36px; }

.u-margin-all5x {
  margin: 40px; }

.u-margin-all5p5x {
  margin: 44px; }

.u-margin-all6x {
  margin: 48px; }

.u-margin-all6p5x {
  margin: 52px; }

.u-margin-all7x {
  margin: 56px; }

.u-margin-all7p5x {
  margin: 60px; }

.u-margin-all8x {
  margin: 64px; }

.u-margin-all8p5x {
  margin: 68px; }

.u-margin-all9x {
  margin: 72px; }

.u-margin-all9p5x {
  margin: 76px; }

.u-margin-all10x {
  margin: 80px; }

.u-margin-all10p5x {
  margin: 84px; }

.u-margin-all11x {
  margin: 88px; }

.u-margin-all11p5x {
  margin: 92px; }

.u-margin-all12x {
  margin: 96px; }

.u-margin-all12p5x {
  margin: 100px; }

.u-margin-all13x {
  margin: 104px; }

.u-margin-all13p5x {
  margin: 108px; }

.u-margin-all14x {
  margin: 112px; }

.u-margin-all14p5x {
  margin: 116px; }

.u-margin-all15x {
  margin: 120px; }

.u-margin-all15p5x {
  margin: 124px; }

.u-margin-all16x {
  margin: 128px; }

.u-margin-all16p5x {
  margin: 132px; }

.u-margin-all17x {
  margin: 136px; }

.u-margin-all17p5x {
  margin: 140px; }

.u-margin-all18x {
  margin: 144px; }

.u-margin-all18p5x {
  margin: 148px; }

.u-margin-all19x {
  margin: 152px; }

.u-margin-all19p5x {
  margin: 156px; }

.u-margin-all20x {
  margin: 160px; }

.u-margin-bottom0p5x {
  margin-bottom: 4px; }

.u-margin-bottom1x {
  margin-bottom: 8px; }

.u-margin-bottom1p5x {
  margin-bottom: 12px; }

.u-margin-bottom2x {
  margin-bottom: 16px; }

.u-margin-bottom2p5x {
  margin-bottom: 20px; }

.u-margin-bottom3x {
  margin-bottom: 24px; }

.u-margin-bottom3p5x {
  margin-bottom: 28px; }

.u-margin-bottom4x {
  margin-bottom: 32px; }

.u-margin-bottom4p5x {
  margin-bottom: 36px; }

.u-margin-bottom5x {
  margin-bottom: 40px; }

.u-margin-bottom5p5x {
  margin-bottom: 44px; }

.u-margin-bottom6x {
  margin-bottom: 48px; }

.u-margin-bottom6p5x {
  margin-bottom: 52px; }

.u-margin-bottom7x {
  margin-bottom: 56px; }

.u-margin-bottom7p5x {
  margin-bottom: 60px; }

.u-margin-bottom8x {
  margin-bottom: 64px; }

.u-margin-bottom8p5x {
  margin-bottom: 68px; }

.u-margin-bottom9x {
  margin-bottom: 72px; }

.u-margin-bottom9p5x {
  margin-bottom: 76px; }

.u-margin-bottom10x {
  margin-bottom: 80px; }

.u-margin-bottom10p5x {
  margin-bottom: 84px; }

.u-margin-bottom11x {
  margin-bottom: 88px; }

.u-margin-bottom11p5x {
  margin-bottom: 92px; }

.u-margin-bottom12x {
  margin-bottom: 96px; }

.u-margin-bottom12p5x {
  margin-bottom: 100px; }

.u-margin-bottom13x {
  margin-bottom: 104px; }

.u-margin-bottom13p5x {
  margin-bottom: 108px; }

.u-margin-bottom14x {
  margin-bottom: 112px; }

.u-margin-bottom14p5x {
  margin-bottom: 116px; }

.u-margin-bottom15x {
  margin-bottom: 120px; }

.u-margin-bottom15p5x {
  margin-bottom: 124px; }

.u-margin-bottom16x {
  margin-bottom: 128px; }

.u-margin-bottom16p5x {
  margin-bottom: 132px; }

.u-margin-bottom17x {
  margin-bottom: 136px; }

.u-margin-bottom17p5x {
  margin-bottom: 140px; }

.u-margin-bottom18x {
  margin-bottom: 144px; }

.u-margin-bottom18p5x {
  margin-bottom: 148px; }

.u-margin-bottom19x {
  margin-bottom: 152px; }

.u-margin-bottom19p5x {
  margin-bottom: 156px; }

.u-margin-bottom20x {
  margin-bottom: 160px; }

.u-margin-left0p5x {
  margin-left: 4px; }

.u-margin-left1x {
  margin-left: 8px; }

.u-margin-left1p5x {
  margin-left: 12px; }

.u-margin-left2x {
  margin-left: 16px; }

.u-margin-left2p5x {
  margin-left: 20px; }

.u-margin-left3x {
  margin-left: 24px; }

.u-margin-left3p5x {
  margin-left: 28px; }

.u-margin-left4x {
  margin-left: 32px; }

.u-margin-left4p5x {
  margin-left: 36px; }

.u-margin-left5x {
  margin-left: 40px; }

.u-margin-left5p5x {
  margin-left: 44px; }

.u-margin-left6x {
  margin-left: 48px; }

.u-margin-left6p5x {
  margin-left: 52px; }

.u-margin-left7x {
  margin-left: 56px; }

.u-margin-left7p5x {
  margin-left: 60px; }

.u-margin-left8x {
  margin-left: 64px; }

.u-margin-left8p5x {
  margin-left: 68px; }

.u-margin-left9x {
  margin-left: 72px; }

.u-margin-left9p5x {
  margin-left: 76px; }

.u-margin-left10x {
  margin-left: 80px; }

.u-margin-left10p5x {
  margin-left: 84px; }

.u-margin-left11x {
  margin-left: 88px; }

.u-margin-left11p5x {
  margin-left: 92px; }

.u-margin-left12x {
  margin-left: 96px; }

.u-margin-left12p5x {
  margin-left: 100px; }

.u-margin-left13x {
  margin-left: 104px; }

.u-margin-left13p5x {
  margin-left: 108px; }

.u-margin-left14x {
  margin-left: 112px; }

.u-margin-left14p5x {
  margin-left: 116px; }

.u-margin-left15x {
  margin-left: 120px; }

.u-margin-left15p5x {
  margin-left: 124px; }

.u-margin-left16x {
  margin-left: 128px; }

.u-margin-left16p5x {
  margin-left: 132px; }

.u-margin-left17x {
  margin-left: 136px; }

.u-margin-left17p5x {
  margin-left: 140px; }

.u-margin-left18x {
  margin-left: 144px; }

.u-margin-left18p5x {
  margin-left: 148px; }

.u-margin-left19x {
  margin-left: 152px; }

.u-margin-left19p5x {
  margin-left: 156px; }

.u-margin-left20x {
  margin-left: 160px; }

.u-margin-right0p5x {
  margin-right: 4px; }

.u-margin-right1x {
  margin-right: 8px; }

.u-margin-right1p5x {
  margin-right: 12px; }

.u-margin-right2x {
  margin-right: 16px; }

.u-margin-right2p5x {
  margin-right: 20px; }

.u-margin-right3x {
  margin-right: 24px; }

.u-margin-right3p5x {
  margin-right: 28px; }

.u-margin-right4x {
  margin-right: 32px; }

.u-margin-right4p5x {
  margin-right: 36px; }

.u-margin-right5x {
  margin-right: 40px; }

.u-margin-right5p5x {
  margin-right: 44px; }

.u-margin-right6x {
  margin-right: 48px; }

.u-margin-right6p5x {
  margin-right: 52px; }

.u-margin-right7x {
  margin-right: 56px; }

.u-margin-right7p5x {
  margin-right: 60px; }

.u-margin-right8x {
  margin-right: 64px; }

.u-margin-right8p5x {
  margin-right: 68px; }

.u-margin-right9x {
  margin-right: 72px; }

.u-margin-right9p5x {
  margin-right: 76px; }

.u-margin-right10x {
  margin-right: 80px; }

.u-margin-right10p5x {
  margin-right: 84px; }

.u-margin-right11x {
  margin-right: 88px; }

.u-margin-right11p5x {
  margin-right: 92px; }

.u-margin-right12x {
  margin-right: 96px; }

.u-margin-right12p5x {
  margin-right: 100px; }

.u-margin-right13x {
  margin-right: 104px; }

.u-margin-right13p5x {
  margin-right: 108px; }

.u-margin-right14x {
  margin-right: 112px; }

.u-margin-right14p5x {
  margin-right: 116px; }

.u-margin-right15x {
  margin-right: 120px; }

.u-margin-right15p5x {
  margin-right: 124px; }

.u-margin-right16x {
  margin-right: 128px; }

.u-margin-right16p5x {
  margin-right: 132px; }

.u-margin-right17x {
  margin-right: 136px; }

.u-margin-right17p5x {
  margin-right: 140px; }

.u-margin-right18x {
  margin-right: 144px; }

.u-margin-right18p5x {
  margin-right: 148px; }

.u-margin-right19x {
  margin-right: 152px; }

.u-margin-right19p5x {
  margin-right: 156px; }

.u-margin-right20x {
  margin-right: 160px; }

.u-margin-top0p5x {
  margin-top: 4px; }

.u-margin-top1x {
  margin-top: 8px; }

.u-margin-top1p5x {
  margin-top: 12px; }

.u-margin-top2x {
  margin-top: 16px; }

.u-margin-top2p5x {
  margin-top: 20px; }

.u-margin-top3x {
  margin-top: 24px; }

.u-margin-top3p5x {
  margin-top: 28px; }

.u-margin-top4x {
  margin-top: 32px; }

.u-margin-top4p5x {
  margin-top: 36px; }

.u-margin-top5x {
  margin-top: 40px; }

.u-margin-top5p5x {
  margin-top: 44px; }

.u-margin-top6x {
  margin-top: 48px; }

.u-margin-top6p5x {
  margin-top: 52px; }

.u-margin-top7x {
  margin-top: 56px; }

.u-margin-top7p5x {
  margin-top: 60px; }

.u-margin-top8x {
  margin-top: 64px; }

.u-margin-top8p5x {
  margin-top: 68px; }

.u-margin-top9x {
  margin-top: 72px; }

.u-margin-top9p5x {
  margin-top: 76px; }

.u-margin-top10x {
  margin-top: 80px; }

.u-margin-top10p5x {
  margin-top: 84px; }

.u-margin-top11x {
  margin-top: 88px; }

.u-margin-top11p5x {
  margin-top: 92px; }

.u-margin-top12x {
  margin-top: 96px; }

.u-margin-top12p5x {
  margin-top: 100px; }

.u-margin-top13x {
  margin-top: 104px; }

.u-margin-top13p5x {
  margin-top: 108px; }

.u-margin-top14x {
  margin-top: 112px; }

.u-margin-top14p5x {
  margin-top: 116px; }

.u-margin-top15x {
  margin-top: 120px; }

.u-margin-top15p5x {
  margin-top: 124px; }

.u-margin-top16x {
  margin-top: 128px; }

.u-margin-top16p5x {
  margin-top: 132px; }

.u-margin-top17x {
  margin-top: 136px; }

.u-margin-top17p5x {
  margin-top: 140px; }

.u-margin-top18x {
  margin-top: 144px; }

.u-margin-top18p5x {
  margin-top: 148px; }

.u-margin-top19x {
  margin-top: 152px; }

.u-margin-top19p5x {
  margin-top: 156px; }

.u-margin-top20x {
  margin-top: 160px; }

.u-margin-xaxis0p5x {
  margin-left: 4px;
  margin-right: 4px; }

.u-margin-xaxis1x {
  margin-left: 8px;
  margin-right: 8px; }

.u-margin-xaxis1p5x {
  margin-left: 12px;
  margin-right: 12px; }

.u-margin-xaxis2x {
  margin-left: 16px;
  margin-right: 16px; }

.u-margin-xaxis2p5x {
  margin-left: 20px;
  margin-right: 20px; }

.u-margin-xaxis3x {
  margin-left: 24px;
  margin-right: 24px; }

.u-margin-xaxis3p5x {
  margin-left: 28px;
  margin-right: 28px; }

.u-margin-xaxis4x {
  margin-left: 32px;
  margin-right: 32px; }

.u-margin-xaxis4p5x {
  margin-left: 36px;
  margin-right: 36px; }

.u-margin-xaxis5x {
  margin-left: 40px;
  margin-right: 40px; }

.u-margin-xaxis5p5x {
  margin-left: 44px;
  margin-right: 44px; }

.u-margin-xaxis6x {
  margin-left: 48px;
  margin-right: 48px; }

.u-margin-xaxis6p5x {
  margin-left: 52px;
  margin-right: 52px; }

.u-margin-xaxis7x {
  margin-left: 56px;
  margin-right: 56px; }

.u-margin-xaxis7p5x {
  margin-left: 60px;
  margin-right: 60px; }

.u-margin-xaxis8x {
  margin-left: 64px;
  margin-right: 64px; }

.u-margin-xaxis8p5x {
  margin-left: 68px;
  margin-right: 68px; }

.u-margin-xaxis9x {
  margin-left: 72px;
  margin-right: 72px; }

.u-margin-xaxis9p5x {
  margin-left: 76px;
  margin-right: 76px; }

.u-margin-xaxis10x {
  margin-left: 80px;
  margin-right: 80px; }

.u-margin-xaxis10p5x {
  margin-left: 84px;
  margin-right: 84px; }

.u-margin-xaxis11x {
  margin-left: 88px;
  margin-right: 88px; }

.u-margin-xaxis11p5x {
  margin-left: 92px;
  margin-right: 92px; }

.u-margin-xaxis12x {
  margin-left: 96px;
  margin-right: 96px; }

.u-margin-xaxis12p5x {
  margin-left: 100px;
  margin-right: 100px; }

.u-margin-xaxis13x {
  margin-left: 104px;
  margin-right: 104px; }

.u-margin-xaxis13p5x {
  margin-left: 108px;
  margin-right: 108px; }

.u-margin-xaxis14x {
  margin-left: 112px;
  margin-right: 112px; }

.u-margin-xaxis14p5x {
  margin-left: 116px;
  margin-right: 116px; }

.u-margin-xaxis15x {
  margin-left: 120px;
  margin-right: 120px; }

.u-margin-xaxis15p5x {
  margin-left: 124px;
  margin-right: 124px; }

.u-margin-xaxis16x {
  margin-left: 128px;
  margin-right: 128px; }

.u-margin-xaxis16p5x {
  margin-left: 132px;
  margin-right: 132px; }

.u-margin-xaxis17x {
  margin-left: 136px;
  margin-right: 136px; }

.u-margin-xaxis17p5x {
  margin-left: 140px;
  margin-right: 140px; }

.u-margin-xaxis18x {
  margin-left: 144px;
  margin-right: 144px; }

.u-margin-xaxis18p5x {
  margin-left: 148px;
  margin-right: 148px; }

.u-margin-xaxis19x {
  margin-left: 152px;
  margin-right: 152px; }

.u-margin-xaxis19p5x {
  margin-left: 156px;
  margin-right: 156px; }

.u-margin-xaxis20x {
  margin-left: 160px;
  margin-right: 160px; }

.u-margin-yaxis0p5x {
  margin-bottom: 4px;
  margin-top: 4px; }

.u-margin-yaxis1x {
  margin-bottom: 8px;
  margin-top: 8px; }

.u-margin-yaxis1p5x {
  margin-bottom: 12px;
  margin-top: 12px; }

.u-margin-yaxis2x {
  margin-bottom: 16px;
  margin-top: 16px; }

.u-margin-yaxis2p5x {
  margin-bottom: 20px;
  margin-top: 20px; }

.u-margin-yaxis3x {
  margin-bottom: 24px;
  margin-top: 24px; }

.u-margin-yaxis3p5x {
  margin-bottom: 28px;
  margin-top: 28px; }

.u-margin-yaxis4x {
  margin-bottom: 32px;
  margin-top: 32px; }

.u-margin-yaxis4p5x {
  margin-bottom: 36px;
  margin-top: 36px; }

.u-margin-yaxis5x {
  margin-bottom: 40px;
  margin-top: 40px; }

.u-margin-yaxis5p5x {
  margin-bottom: 44px;
  margin-top: 44px; }

.u-margin-yaxis6x {
  margin-bottom: 48px;
  margin-top: 48px; }

.u-margin-yaxis6p5x {
  margin-bottom: 52px;
  margin-top: 52px; }

.u-margin-yaxis7x {
  margin-bottom: 56px;
  margin-top: 56px; }

.u-margin-yaxis7p5x {
  margin-bottom: 60px;
  margin-top: 60px; }

.u-margin-yaxis8x {
  margin-bottom: 64px;
  margin-top: 64px; }

.u-margin-yaxis8p5x {
  margin-bottom: 68px;
  margin-top: 68px; }

.u-margin-yaxis9x {
  margin-bottom: 72px;
  margin-top: 72px; }

.u-margin-yaxis9p5x {
  margin-bottom: 76px;
  margin-top: 76px; }

.u-margin-yaxis10x {
  margin-bottom: 80px;
  margin-top: 80px; }

.u-margin-yaxis10p5x {
  margin-bottom: 84px;
  margin-top: 84px; }

.u-margin-yaxis11x {
  margin-bottom: 88px;
  margin-top: 88px; }

.u-margin-yaxis11p5x {
  margin-bottom: 92px;
  margin-top: 92px; }

.u-margin-yaxis12x {
  margin-bottom: 96px;
  margin-top: 96px; }

.u-margin-yaxis12p5x {
  margin-bottom: 100px;
  margin-top: 100px; }

.u-margin-yaxis13x {
  margin-bottom: 104px;
  margin-top: 104px; }

.u-margin-yaxis13p5x {
  margin-bottom: 108px;
  margin-top: 108px; }

.u-margin-yaxis14x {
  margin-bottom: 112px;
  margin-top: 112px; }

.u-margin-yaxis14p5x {
  margin-bottom: 116px;
  margin-top: 116px; }

.u-margin-yaxis15x {
  margin-bottom: 120px;
  margin-top: 120px; }

.u-margin-yaxis15p5x {
  margin-bottom: 124px;
  margin-top: 124px; }

.u-margin-yaxis16x {
  margin-bottom: 128px;
  margin-top: 128px; }

.u-margin-yaxis16p5x {
  margin-bottom: 132px;
  margin-top: 132px; }

.u-margin-yaxis17x {
  margin-bottom: 136px;
  margin-top: 136px; }

.u-margin-yaxis17p5x {
  margin-bottom: 140px;
  margin-top: 140px; }

.u-margin-yaxis18x {
  margin-bottom: 144px;
  margin-top: 144px; }

.u-margin-yaxis18p5x {
  margin-bottom: 148px;
  margin-top: 148px; }

.u-margin-yaxis19x {
  margin-bottom: 152px;
  margin-top: 152px; }

.u-margin-yaxis19p5x {
  margin-bottom: 156px;
  margin-top: 156px; }

.u-margin-yaxis20x {
  margin-bottom: 160px;
  margin-top: 160px; }

.u-margin-auto {
  margin: auto; }

.u-padding-all0p5x {
  padding: 4px; }

.u-padding-all1x {
  padding: 8px; }

.u-padding-all1p5x {
  padding: 12px; }

.u-padding-all2x {
  padding: 16px; }

.u-padding-all2p5x {
  padding: 20px; }

.u-padding-all3x {
  padding: 24px; }

.u-padding-all3p5x {
  padding: 28px; }

.u-padding-all4x {
  padding: 32px; }

.u-padding-all4p5x {
  padding: 36px; }

.u-padding-all5x {
  padding: 40px; }

.u-padding-all5p5x {
  padding: 44px; }

.u-padding-all6x {
  padding: 48px; }

.u-padding-all6p5x {
  padding: 52px; }

.u-padding-all7x {
  padding: 56px; }

.u-padding-all7p5x {
  padding: 60px; }

.u-padding-all8x {
  padding: 64px; }

.u-padding-all8p5x {
  padding: 68px; }

.u-padding-all9x {
  padding: 72px; }

.u-padding-all9p5x {
  padding: 76px; }

.u-padding-all10x {
  padding: 80px; }

.u-padding-all10p5x {
  padding: 84px; }

.u-padding-all11x {
  padding: 88px; }

.u-padding-all11p5x {
  padding: 92px; }

.u-padding-all12x {
  padding: 96px; }

.u-padding-all12p5x {
  padding: 100px; }

.u-padding-all13x {
  padding: 104px; }

.u-padding-all13p5x {
  padding: 108px; }

.u-padding-all14x {
  padding: 112px; }

.u-padding-all14p5x {
  padding: 116px; }

.u-padding-all15x {
  padding: 120px; }

.u-padding-all15p5x {
  padding: 124px; }

.u-padding-all16x {
  padding: 128px; }

.u-padding-all16p5x {
  padding: 132px; }

.u-padding-all17x {
  padding: 136px; }

.u-padding-all17p5x {
  padding: 140px; }

.u-padding-all18x {
  padding: 144px; }

.u-padding-all18p5x {
  padding: 148px; }

.u-padding-all19x {
  padding: 152px; }

.u-padding-all19p5x {
  padding: 156px; }

.u-padding-all20x {
  padding: 160px; }

.u-padding-bottom0p5x {
  padding-bottom: 4px; }

.u-padding-bottom1x {
  padding-bottom: 8px; }

.u-padding-bottom1p5x {
  padding-bottom: 12px; }

.u-padding-bottom2x {
  padding-bottom: 16px; }

.u-padding-bottom2p5x {
  padding-bottom: 20px; }

.u-padding-bottom3x {
  padding-bottom: 24px; }

.u-padding-bottom3p5x {
  padding-bottom: 28px; }

.u-padding-bottom4x {
  padding-bottom: 32px; }

.u-padding-bottom4p5x {
  padding-bottom: 36px; }

.u-padding-bottom5x {
  padding-bottom: 40px; }

.u-padding-bottom5p5x {
  padding-bottom: 44px; }

.u-padding-bottom6x {
  padding-bottom: 48px; }

.u-padding-bottom6p5x {
  padding-bottom: 52px; }

.u-padding-bottom7x {
  padding-bottom: 56px; }

.u-padding-bottom7p5x {
  padding-bottom: 60px; }

.u-padding-bottom8x {
  padding-bottom: 64px; }

.u-padding-bottom8p5x {
  padding-bottom: 68px; }

.u-padding-bottom9x {
  padding-bottom: 72px; }

.u-padding-bottom9p5x {
  padding-bottom: 76px; }

.u-padding-bottom10x {
  padding-bottom: 80px; }

.u-padding-bottom10p5x {
  padding-bottom: 84px; }

.u-padding-bottom11x {
  padding-bottom: 88px; }

.u-padding-bottom11p5x {
  padding-bottom: 92px; }

.u-padding-bottom12x {
  padding-bottom: 96px; }

.u-padding-bottom12p5x {
  padding-bottom: 100px; }

.u-padding-bottom13x {
  padding-bottom: 104px; }

.u-padding-bottom13p5x {
  padding-bottom: 108px; }

.u-padding-bottom14x {
  padding-bottom: 112px; }

.u-padding-bottom14p5x {
  padding-bottom: 116px; }

.u-padding-bottom15x {
  padding-bottom: 120px; }

.u-padding-bottom15p5x {
  padding-bottom: 124px; }

.u-padding-bottom16x {
  padding-bottom: 128px; }

.u-padding-bottom16p5x {
  padding-bottom: 132px; }

.u-padding-bottom17x {
  padding-bottom: 136px; }

.u-padding-bottom17p5x {
  padding-bottom: 140px; }

.u-padding-bottom18x {
  padding-bottom: 144px; }

.u-padding-bottom18p5x {
  padding-bottom: 148px; }

.u-padding-bottom19x {
  padding-bottom: 152px; }

.u-padding-bottom19p5x {
  padding-bottom: 156px; }

.u-padding-bottom20x {
  padding-bottom: 160px; }

.u-padding-left0p5x {
  padding-left: 4px; }

.u-padding-left1x {
  padding-left: 8px; }

.u-padding-left1p5x {
  padding-left: 12px; }

.u-padding-left2x {
  padding-left: 16px; }

.u-padding-left2p5x {
  padding-left: 20px; }

.u-padding-left3x {
  padding-left: 24px; }

.u-padding-left3p5x {
  padding-left: 28px; }

.u-padding-left4x {
  padding-left: 32px; }

.u-padding-left4p5x {
  padding-left: 36px; }

.u-padding-left5x {
  padding-left: 40px; }

.u-padding-left5p5x {
  padding-left: 44px; }

.u-padding-left6x {
  padding-left: 48px; }

.u-padding-left6p5x {
  padding-left: 52px; }

.u-padding-left7x {
  padding-left: 56px; }

.u-padding-left7p5x {
  padding-left: 60px; }

.u-padding-left8x {
  padding-left: 64px; }

.u-padding-left8p5x {
  padding-left: 68px; }

.u-padding-left9x {
  padding-left: 72px; }

.u-padding-left9p5x {
  padding-left: 76px; }

.u-padding-left10x {
  padding-left: 80px; }

.u-padding-left10p5x {
  padding-left: 84px; }

.u-padding-left11x {
  padding-left: 88px; }

.u-padding-left11p5x {
  padding-left: 92px; }

.u-padding-left12x {
  padding-left: 96px; }

.u-padding-left12p5x {
  padding-left: 100px; }

.u-padding-left13x {
  padding-left: 104px; }

.u-padding-left13p5x {
  padding-left: 108px; }

.u-padding-left14x {
  padding-left: 112px; }

.u-padding-left14p5x {
  padding-left: 116px; }

.u-padding-left15x {
  padding-left: 120px; }

.u-padding-left15p5x {
  padding-left: 124px; }

.u-padding-left16x {
  padding-left: 128px; }

.u-padding-left16p5x {
  padding-left: 132px; }

.u-padding-left17x {
  padding-left: 136px; }

.u-padding-left17p5x {
  padding-left: 140px; }

.u-padding-left18x {
  padding-left: 144px; }

.u-padding-left18p5x {
  padding-left: 148px; }

.u-padding-left19x {
  padding-left: 152px; }

.u-padding-left19p5x {
  padding-left: 156px; }

.u-padding-left20x {
  padding-left: 160px; }

.u-padding-right0p5x {
  padding-right: 4px; }

.u-padding-right1x {
  padding-right: 8px; }

.u-padding-right1p5x {
  padding-right: 12px; }

.u-padding-right2x {
  padding-right: 16px; }

.u-padding-right2p5x {
  padding-right: 20px; }

.u-padding-right3x {
  padding-right: 24px; }

.u-padding-right3p5x {
  padding-right: 28px; }

.u-padding-right4x {
  padding-right: 32px; }

.u-padding-right4p5x {
  padding-right: 36px; }

.u-padding-right5x {
  padding-right: 40px; }

.u-padding-right5p5x {
  padding-right: 44px; }

.u-padding-right6x {
  padding-right: 48px; }

.u-padding-right6p5x {
  padding-right: 52px; }

.u-padding-right7x {
  padding-right: 56px; }

.u-padding-right7p5x {
  padding-right: 60px; }

.u-padding-right8x {
  padding-right: 64px; }

.u-padding-right8p5x {
  padding-right: 68px; }

.u-padding-right9x {
  padding-right: 72px; }

.u-padding-right9p5x {
  padding-right: 76px; }

.u-padding-right10x {
  padding-right: 80px; }

.u-padding-right10p5x {
  padding-right: 84px; }

.u-padding-right11x {
  padding-right: 88px; }

.u-padding-right11p5x {
  padding-right: 92px; }

.u-padding-right12x {
  padding-right: 96px; }

.u-padding-right12p5x {
  padding-right: 100px; }

.u-padding-right13x {
  padding-right: 104px; }

.u-padding-right13p5x {
  padding-right: 108px; }

.u-padding-right14x {
  padding-right: 112px; }

.u-padding-right14p5x {
  padding-right: 116px; }

.u-padding-right15x {
  padding-right: 120px; }

.u-padding-right15p5x {
  padding-right: 124px; }

.u-padding-right16x {
  padding-right: 128px; }

.u-padding-right16p5x {
  padding-right: 132px; }

.u-padding-right17x {
  padding-right: 136px; }

.u-padding-right17p5x {
  padding-right: 140px; }

.u-padding-right18x {
  padding-right: 144px; }

.u-padding-right18p5x {
  padding-right: 148px; }

.u-padding-right19x {
  padding-right: 152px; }

.u-padding-right19p5x {
  padding-right: 156px; }

.u-padding-right20x {
  padding-right: 160px; }

.u-padding-top0p5x {
  padding-top: 4px; }

.u-padding-top1x {
  padding-top: 8px; }

.u-padding-top1p5x {
  padding-top: 12px; }

.u-padding-top2x {
  padding-top: 16px; }

.u-padding-top2p5x {
  padding-top: 20px; }

.u-padding-top3x {
  padding-top: 24px; }

.u-padding-top3p5x {
  padding-top: 28px; }

.u-padding-top4x {
  padding-top: 32px; }

.u-padding-top4p5x {
  padding-top: 36px; }

.u-padding-top5x {
  padding-top: 40px; }

.u-padding-top5p5x {
  padding-top: 44px; }

.u-padding-top6x {
  padding-top: 48px; }

.u-padding-top6p5x {
  padding-top: 52px; }

.u-padding-top7x {
  padding-top: 56px; }

.u-padding-top7p5x {
  padding-top: 60px; }

.u-padding-top8x {
  padding-top: 64px; }

.u-padding-top8p5x {
  padding-top: 68px; }

.u-padding-top9x {
  padding-top: 72px; }

.u-padding-top9p5x {
  padding-top: 76px; }

.u-padding-top10x {
  padding-top: 80px; }

.u-padding-top10p5x {
  padding-top: 84px; }

.u-padding-top11x {
  padding-top: 88px; }

.u-padding-top11p5x {
  padding-top: 92px; }

.u-padding-top12x {
  padding-top: 96px; }

.u-padding-top12p5x {
  padding-top: 100px; }

.u-padding-top13x {
  padding-top: 104px; }

.u-padding-top13p5x {
  padding-top: 108px; }

.u-padding-top14x {
  padding-top: 112px; }

.u-padding-top14p5x {
  padding-top: 116px; }

.u-padding-top15x {
  padding-top: 120px; }

.u-padding-top15p5x {
  padding-top: 124px; }

.u-padding-top16x {
  padding-top: 128px; }

.u-padding-top16p5x {
  padding-top: 132px; }

.u-padding-top17x {
  padding-top: 136px; }

.u-padding-top17p5x {
  padding-top: 140px; }

.u-padding-top18x {
  padding-top: 144px; }

.u-padding-top18p5x {
  padding-top: 148px; }

.u-padding-top19x {
  padding-top: 152px; }

.u-padding-top19p5x {
  padding-top: 156px; }

.u-padding-top20x {
  padding-top: 160px; }

.u-padding-xaxis0p5x {
  padding-left: 4px;
  padding-right: 4px; }

.u-padding-xaxis1x {
  padding-left: 8px;
  padding-right: 8px; }

.u-padding-xaxis1p5x {
  padding-left: 12px;
  padding-right: 12px; }

.u-padding-xaxis2x {
  padding-left: 16px;
  padding-right: 16px; }

.u-padding-xaxis2p5x {
  padding-left: 20px;
  padding-right: 20px; }

.u-padding-xaxis3x {
  padding-left: 24px;
  padding-right: 24px; }

.u-padding-xaxis3p5x {
  padding-left: 28px;
  padding-right: 28px; }

.u-padding-xaxis4x {
  padding-left: 32px;
  padding-right: 32px; }

.u-padding-xaxis4p5x {
  padding-left: 36px;
  padding-right: 36px; }

.u-padding-xaxis5x {
  padding-left: 40px;
  padding-right: 40px; }

.u-padding-xaxis5p5x {
  padding-left: 44px;
  padding-right: 44px; }

.u-padding-xaxis6x {
  padding-left: 48px;
  padding-right: 48px; }

.u-padding-xaxis6p5x {
  padding-left: 52px;
  padding-right: 52px; }

.u-padding-xaxis7x {
  padding-left: 56px;
  padding-right: 56px; }

.u-padding-xaxis7p5x {
  padding-left: 60px;
  padding-right: 60px; }

.u-padding-xaxis8x {
  padding-left: 64px;
  padding-right: 64px; }

.u-padding-xaxis8p5x {
  padding-left: 68px;
  padding-right: 68px; }

.u-padding-xaxis9x {
  padding-left: 72px;
  padding-right: 72px; }

.u-padding-xaxis9p5x {
  padding-left: 76px;
  padding-right: 76px; }

.u-padding-xaxis10x {
  padding-left: 80px;
  padding-right: 80px; }

.u-padding-xaxis10p5x {
  padding-left: 84px;
  padding-right: 84px; }

.u-padding-xaxis11x {
  padding-left: 88px;
  padding-right: 88px; }

.u-padding-xaxis11p5x {
  padding-left: 92px;
  padding-right: 92px; }

.u-padding-xaxis12x {
  padding-left: 96px;
  padding-right: 96px; }

.u-padding-xaxis12p5x {
  padding-left: 100px;
  padding-right: 100px; }

.u-padding-xaxis13x {
  padding-left: 104px;
  padding-right: 104px; }

.u-padding-xaxis13p5x {
  padding-left: 108px;
  padding-right: 108px; }

.u-padding-xaxis14x {
  padding-left: 112px;
  padding-right: 112px; }

.u-padding-xaxis14p5x {
  padding-left: 116px;
  padding-right: 116px; }

.u-padding-xaxis15x {
  padding-left: 120px;
  padding-right: 120px; }

.u-padding-xaxis15p5x {
  padding-left: 124px;
  padding-right: 124px; }

.u-padding-xaxis16x {
  padding-left: 128px;
  padding-right: 128px; }

.u-padding-xaxis16p5x {
  padding-left: 132px;
  padding-right: 132px; }

.u-padding-xaxis17x {
  padding-left: 136px;
  padding-right: 136px; }

.u-padding-xaxis17p5x {
  padding-left: 140px;
  padding-right: 140px; }

.u-padding-xaxis18x {
  padding-left: 144px;
  padding-right: 144px; }

.u-padding-xaxis18p5x {
  padding-left: 148px;
  padding-right: 148px; }

.u-padding-xaxis19x {
  padding-left: 152px;
  padding-right: 152px; }

.u-padding-xaxis19p5x {
  padding-left: 156px;
  padding-right: 156px; }

.u-padding-xaxis20x {
  padding-left: 160px;
  padding-right: 160px; }

.u-padding-yaxis0p5x {
  padding-bottom: 4px;
  padding-top: 4px; }

.u-padding-yaxis1x {
  padding-bottom: 8px;
  padding-top: 8px; }

.u-padding-yaxis1p5x {
  padding-bottom: 12px;
  padding-top: 12px; }

.u-padding-yaxis2x {
  padding-bottom: 16px;
  padding-top: 16px; }

.u-padding-yaxis2p5x {
  padding-bottom: 20px;
  padding-top: 20px; }

.u-padding-yaxis3x {
  padding-bottom: 24px;
  padding-top: 24px; }

.u-padding-yaxis3p5x {
  padding-bottom: 28px;
  padding-top: 28px; }

.u-padding-yaxis4x {
  padding-bottom: 32px;
  padding-top: 32px; }

.u-padding-yaxis4p5x {
  padding-bottom: 36px;
  padding-top: 36px; }

.u-padding-yaxis5x {
  padding-bottom: 40px;
  padding-top: 40px; }

.u-padding-yaxis5p5x {
  padding-bottom: 44px;
  padding-top: 44px; }

.u-padding-yaxis6x {
  padding-bottom: 48px;
  padding-top: 48px; }

.u-padding-yaxis6p5x {
  padding-bottom: 52px;
  padding-top: 52px; }

.u-padding-yaxis7x {
  padding-bottom: 56px;
  padding-top: 56px; }

.u-padding-yaxis7p5x {
  padding-bottom: 60px;
  padding-top: 60px; }

.u-padding-yaxis8x {
  padding-bottom: 64px;
  padding-top: 64px; }

.u-padding-yaxis8p5x {
  padding-bottom: 68px;
  padding-top: 68px; }

.u-padding-yaxis9x {
  padding-bottom: 72px;
  padding-top: 72px; }

.u-padding-yaxis9p5x {
  padding-bottom: 76px;
  padding-top: 76px; }

.u-padding-yaxis10x {
  padding-bottom: 80px;
  padding-top: 80px; }

.u-padding-yaxis10p5x {
  padding-bottom: 84px;
  padding-top: 84px; }

.u-padding-yaxis11x {
  padding-bottom: 88px;
  padding-top: 88px; }

.u-padding-yaxis11p5x {
  padding-bottom: 92px;
  padding-top: 92px; }

.u-padding-yaxis12x {
  padding-bottom: 96px;
  padding-top: 96px; }

.u-padding-yaxis12p5x {
  padding-bottom: 100px;
  padding-top: 100px; }

.u-padding-yaxis13x {
  padding-bottom: 104px;
  padding-top: 104px; }

.u-padding-yaxis13p5x {
  padding-bottom: 108px;
  padding-top: 108px; }

.u-padding-yaxis14x {
  padding-bottom: 112px;
  padding-top: 112px; }

.u-padding-yaxis14p5x {
  padding-bottom: 116px;
  padding-top: 116px; }

.u-padding-yaxis15x {
  padding-bottom: 120px;
  padding-top: 120px; }

.u-padding-yaxis15p5x {
  padding-bottom: 124px;
  padding-top: 124px; }

.u-padding-yaxis16x {
  padding-bottom: 128px;
  padding-top: 128px; }

.u-padding-yaxis16p5x {
  padding-bottom: 132px;
  padding-top: 132px; }

.u-padding-yaxis17x {
  padding-bottom: 136px;
  padding-top: 136px; }

.u-padding-yaxis17p5x {
  padding-bottom: 140px;
  padding-top: 140px; }

.u-padding-yaxis18x {
  padding-bottom: 144px;
  padding-top: 144px; }

.u-padding-yaxis18p5x {
  padding-bottom: 148px;
  padding-top: 148px; }

.u-padding-yaxis19x {
  padding-bottom: 152px;
  padding-top: 152px; }

.u-padding-yaxis19p5x {
  padding-bottom: 156px;
  padding-top: 156px; }

.u-padding-yaxis20x {
  padding-bottom: 160px;
  padding-top: 160px; }

.u-text-body {
  font-family: Arial;
  font-size: 24px;
  font-weight: normal;
  line-height: 32px; }

.u-text-link {
  font-family: Arial;
  font-size: 24px;
  font-weight: normal;
  line-height: 32px;
  color: #418fde; }

.u-text-h1 {
  font-family: Arial Black;
  font-size: 32px;
  line-height: 40px; }

.u-text-h2 {
  font-family: Arial Black;
  font-size: 24px;
  line-height: 32px; }

.u-text-h2-shadow {
  color: #fff;
  font-family: Bangers;
  font-weight: normal;
  letter-spacing: 1.38px;
  text-shadow: 2px 3px 1px #231F20; }

.u-text-h3 {
  font-size: 24px;
  line-height: 24px; }

.u-text-h3-shadow {
  color: #fff;
  font-family: Bangers;
  font-weight: normal;
  letter-spacing: 1.38px;
  text-shadow: 2px 3px 1px #231F20;
  font-size: 24px;
  line-height: 24px; }

.u-text-h4 {
  font-family: Arial;
  font-size: 16px;
  font-weight: bold;
  line-height: 24px; }

.u-text-small {
  font-family: Arial;
  font-size: 16px;
  line-height: 24px; }

.u-text-title1 {
  font-family: CCKillJoy;
  font-size: 96px;
  font-weight: normal;
  line-height: 80px; }

.u-text-title2 {
  font-family: CCKillJoy;
  font-size: 40px;
  line-height: 40px; }

.u-text-title3 {
  font-family: CCKillJoy;
  font-size: 20px;
  line-height: 24px; }

.u-text-body {
  font-family: Arial;
  font-size: 24px;
  font-weight: normal;
  line-height: 32px; }

.u-text-link {
  font-family: Arial;
  font-size: 24px;
  font-weight: normal;
  line-height: 32px;
  color: #418fde; }

.u-text-h1 {
  font-family: Arial Black;
  font-size: 32px;
  line-height: 40px; }

.u-text-h2 {
  font-family: Arial Black;
  font-size: 24px;
  line-height: 32px; }

.u-text-h2-shadow {
  color: #fff;
  font-family: Bangers;
  font-weight: normal;
  letter-spacing: 1.38px;
  text-shadow: 2px 3px 1px #231F20; }

.u-text-h3 {
  font-size: 24px;
  line-height: 24px; }

.u-text-h3-shadow {
  color: #fff;
  font-family: Bangers;
  font-weight: normal;
  letter-spacing: 1.38px;
  text-shadow: 2px 3px 1px #231F20;
  font-size: 24px;
  line-height: 24px; }

.u-text-h4 {
  font-family: Arial;
  font-size: 16px;
  font-weight: bold;
  line-height: 24px; }

.u-text-small {
  font-family: Arial;
  font-size: 16px;
  line-height: 24px; }

.u-text-title1 {
  font-family: CCKillJoy;
  font-size: 96px;
  font-weight: normal;
  line-height: 80px; }

.u-text-title2 {
  font-family: CCKillJoy;
  font-size: 40px;
  line-height: 40px; }

.u-text-title3 {
  font-family: CCKillJoy;
  font-size: 20px;
  line-height: 24px; }

.u-text-bold {
  font-weight: bold; }

.u-text-italic {
  font-style: italic; }

.u-text-uppercase {
  text-transform: uppercase; }

.u-text-center {
  text-align: center; }

.u-text-right {
  text-align: right; }

.u-text-nowrap {
  white-space: nowrap; }

.u-text-breakword {
  hyphens: auto;
  overflow-wrap: break-word;
  word-wrap: break-word; }

.u-text-colorlight1 {
  color: #fff; }

.u-text-colorerror {
  color: #cb333b; }

.u-text-colorinteractive1 {
  color: #418fde; }

.u-text-shadow {
  text-shadow: 2px 3px 1px #231F20; }

.u-text-anchor a {
  color: #418fde; }

.u-width {
  width: auto; }

.u-width-100 {
  width: 100%; }

.u-width-max540px {
  max-width: 540px; }

.actionlist {
  display: flex; }
  .actionlist:not(:last-child) {
    border-bottom: 1px solid #e1e0e1;
    padding-bottom: 40px; }
  .actionlist .actionlist-x-image,
  .actionlist .actionlist-x-imageportrait {
    background-color: #e1e0e1;
    border-radius: 80px;
    flex-shrink: 0;
    height: 160px;
    width: 160px;
    overflow: hidden;
    position: relative; }
    .actionlist .actionlist-x-image img,
    .actionlist .actionlist-x-imageportrait img {
      display: block;
      height: 100%;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: auto; }
    .actionlist .actionlist-x-image::before,
    .actionlist .actionlist-x-imageportrait::before {
      background: url("images/icon-book-light1.svg") no-repeat center center;
      background-size: 64px 64px;
      bottom: 0;
      content: '';
      left: 0;
      position: absolute;
      right: 0;
      transform: rotate(-15deg);
      top: 0; }
  .actionlist .actionlist-x-imageportrait img {
    height: auto;
    width: 100%; }
  .actionlist .actionlist-x-text {
    flex-grow: 1;
    margin-left: 16px;
    text-align: center; }
    .actionlist .actionlist-x-text h3 {
      font-family: Arial Black;
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 16px; }

.background {
  background-image: url("images/signin-background.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  box-sizing: border-box;
  min-height: 100vh;
  position: relative;
  width: 100%;
  z-index: 1; }
  .background::before {
    background-color: #231f20;
    content: '';
    height: 100%;
    left: 0;
    opacity: .6;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1; }

.backgroundcolor {
  background-color: transparent; }

.backgroundcolor-light1 {
  background-color: #fff; }

.backgroundrepeating {
  background-color: #fff;
  position: relative;
  min-height: 100vh; }
  .backgroundrepeating img {
    z-index: 1; }
  .backgroundrepeating::before {
    background-image: url("images/backgroundrepeating.svg");
    background-repeat: repeat;
    content: "";
    left: 0;
    min-height: 100%;
    opacity: .5;
    position: fixed;
    pointer-events: none;
    top: 0;
    width: 100%;
    z-index: -1; }

.backgroundrepeating-modal::before {
  z-index: 0; }

.banner {
  background-image: url("images/banner-background.png");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  width: 100%;
  z-index: 1; }
  .banner::before {
    background-color: #231f20;
    content: '';
    height: 100%;
    left: 0;
    opacity: .6;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1; }
  .banner .banner-x-titlecontainer {
    padding-bottom: 64px;
    padding-top: 72px; }
  .banner .banner-x-controls {
    right: 24px;
    position: absolute;
    top: 24px; }

.banner-fixedheightcentered {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 429px;
  justify-content: center; }
  .banner-fixedheightcentered .banner-fixedheightcentered-x-heading {
    left: calc(50% - 144px);
    padding: 12px 0;
    position: absolute;
    top: 0; }

.bannertext {
  font-family: CCKillJoy;
  font-size: 40px;
  line-height: 40px;
  color: #fff;
  margin-left: auto;
  margin-right: auto;
  max-width: 704px; }

.bannertextbody {
  font-family: Arial;
  font-size: 24px;
  font-weight: normal;
  line-height: 32px;
  color: #fff; }

.bannertextsubheader {
  font-family: Arial;
  font-size: 24px;
  font-weight: normal;
  line-height: 32px;
  color: #fff;
  font-weight: bold; }

.actionbutton {
  align-items: center;
  background-image: url("images/buttonframe-large1-sprite.svg");
  background-repeat: no-repeat;
  background-size: 100% auto;
  box-sizing: border-box;
  display: flex;
  height: 96px;
  justify-content: center;
  padding: 18px 15px 30px 18px;
  position: relative;
  max-width: 258px;
  width: 312px;
  z-index: 1; }
  .actionbutton:hover {
    background-position: 0 -96px; }
  .actionbutton:active {
    background-position: 0 -192px; }
    .actionbutton:active span, .actionbutton:active::after, .actionbutton:active::before {
      left: -7px;
      position: relative;
      top: 7px; }
  .actionbutton span {
    font-family: CCKillJoy;
    font-size: 40px;
    line-height: 40px;
    color: #fff; }
  .actionbutton.actionbutton-is-disabled {
    background-position: 0 -480px;
    pointer-events: none;
    user-select: none; }
  .actionbutton.actionbutton-is-done {
    background-position: 0 -288px; }
    .actionbutton.actionbutton-is-done:active {
      background-position: 0 -384px; }
    .actionbutton.actionbutton-is-done span {
      color: #231f20; }

.actionbutton-icon::after, .actionbutton-icon::before,
.actionbutton-iconleft::after,
.actionbutton-iconleft::before,
.actionbutton-iconright::after,
.actionbutton-iconright::before {
  background-repeat: no-repeat;
  content: '';
  display: block;
  height: 48px;
  width: 48px; }

.actionbutton,
.actionbuttonlarge2 {
  height: 96px;
  max-width: 312px; }

.actionbutton-iconchesspiece.actionbutton-iconleft::before {
  background-image: url("images/icon-chesspiece-light1.svg");
  transform: scaleX(-1); }

.actionbutton-iconchesspiece.actionbutton-iconleft.actionbutton-is-done::before {
  background-image: url("images/icon-chesspiece-dark1.svg"); }

.actionbutton-iconchesspiece.actionbutton-iconright::after {
  background-image: url("images/icon-chesspiece-light1.svg"); }

.actionbutton-iconchesspiece.actionbutton-iconright.actionbutton-is-done::after {
  background-image: url("images/icon-chesspiece-dark1.svg"); }

.actionbutton-iconarrow.actionbutton-iconleft::before {
  background-image: url("images/icon-arrow-light1.svg");
  transform: scaleX(-1); }

.actionbutton-iconarrow.actionbutton-iconleft.actionbutton-is-done::before {
  background-image: url("images/icon-arrow-dark1.svg"); }

.actionbutton-iconarrow.actionbutton-iconright::after {
  background-image: url("images/icon-arrow-light1.svg"); }

.actionbutton-iconarrow.actionbutton-iconright.actionbutton-is-done::after {
  background-image: url("images/icon-arrow-dark1.svg"); }

.actionbutton-iconbook.actionbutton-iconleft::before {
  background-image: url("images/icon-book-light1.svg"); }

.actionbutton-iconbook.actionbutton-iconleft.actionbutton-is-done::before {
  background-image: url("images/icon-book-dark1.svg"); }

.actionbutton-iconbook.actionbutton-iconright::after {
  background-image: url("images/icon-book-light1.svg"); }

.actionbutton-iconbook.actionbutton-iconright.actionbutton-is-done::after {
  background-image: url("images/icon-book-dark1.svg"); }

.actionbutton-iconcheck.actionbutton-iconleft::before {
  background-image: url("images/icon-check-dark1.svg"); }

.actionbutton-iconcheck.actionbutton-iconleft.actionbutton-is-done::before {
  background-image: url("images/icon-check-dark1.svg"); }

.actionbutton-iconcheck.actionbutton-iconright::after {
  background-image: url("images/icon-check-dark1.svg"); }

.actionbutton-iconcheck.actionbutton-iconright.actionbutton-is-done::after {
  background-image: url("images/icon-check-dark1.svg"); }

.actionbutton-iconpencil.actionbutton-iconleft::before {
  background-image: url("images/icon-pencil-light1.svg"); }

.actionbutton-iconpencil.actionbutton-iconleft.actionbutton-is-done::before {
  background-image: url("images/icon-pencil-dark1.svg"); }

.actionbutton-iconpencil.actionbutton-iconright::after {
  background-image: url("images/icon-pencil-light1.svg"); }

.actionbutton-iconpencil.actionbutton-iconright.actionbutton-is-done::after {
  background-image: url("images/icon-pencil-dark1.svg"); }

.actionbutton-icongame.actionbutton-iconleft::before {
  background-image: url("images/icon-game-light1.svg"); }

.actionbutton-icongame.actionbutton-iconleft.actionbutton-is-done::before {
  background-image: url("images/icon-game-dark1.svg"); }

.actionbutton-icongame.actionbutton-iconright::after {
  background-image: url("images/icon-game-light1.svg"); }

.actionbutton-icongame.actionbutton-iconright.actionbutton-is-done::after {
  background-image: url("images/icon-game-dark1.svg"); }

.actionbutton-iconleft::after {
  content: none; }

.actionbutton-iconleft::before {
  margin-right: 16px; }

.actionbutton-iconlogout.actionbutton-iconleft::before {
  background-image: url("images/icon-logout-light1.svg");
  transform: scaleX(-1); }

.actionbutton-iconlogout.actionbutton-iconright::after {
  background-image: url("images/icon-logout-light1.svg");
  transform: scaleX(-1); }

.actionbutton-iconscissors.actionbutton-iconleft::before {
  background-image: url("images/icon-scissors-light1.svg"); }

.actionbutton-iconscissors.actionbutton-iconleft.actionbutton-is-done::before {
  background-image: url("images/icon-scissors-dark1.svg"); }

.actionbutton-iconscissors.actionbutton-iconright::after {
  background-image: url("images/icon-scissors-light1.svg"); }

.actionbutton-iconscissors.actionbutton-iconright.actionbutton-is-done::after {
  background-image: url("images/icon-scissors-dark1.svg"); }

.actionbutton-iconplay.actionbutton-iconleft::before {
  background-image: url("images/icon-play-light1.svg"); }

.actionbutton-iconplay.actionbutton-iconleft.actionbutton-is-done::before {
  background-image: url("images/icon-play-dark1.svg"); }

.actionbutton-iconplay.actionbutton-iconright::after {
  background-image: url("images/icon-play-light1.svg"); }

.actionbutton-iconplay.actionbutton-iconright.actionbutton-is-done::before {
  background-image: url("images/icon-play-dark1.svg"); }

.actionbutton-iconprint.actionbutton-iconleft::before {
  background-image: url("images/icon-print-light1.svg"); }

.actionbutton-iconprint.actionbutton-iconleft.actionbutton-is-done::before {
  background-image: url("images/icon-print-dark1.svg"); }

.actionbutton-iconprint.actionbutton-iconright::after {
  background-image: url("images/icon-print-light1.svg"); }

.actionbutton-iconprint.actionbutton-iconright.actionbutton-is-done::after {
  background-image: url("images/icon-print-dark1.svg"); }

.actionbutton-iconright::after {
  margin-left: 16px; }

.actionbutton-iconright::before {
  content: none; }

.actionbutton-iconsmall::after, .actionbutton-iconsmall::before {
  height: 24px;
  width: 24px; }

.actionbutton-large2 {
  background-image: url("images/buttonframe-large2-sprite.svg"); }

.actionbutton-medium {
  background-image: url("images/buttonframe-medium-sprite.svg");
  max-width: 258px; }

.actionbutton-smalltext span {
  font-family: CCKillJoy;
  font-size: 20px;
  line-height: 24px; }

.addnotebookitembutton {
  background-image: url("images/addnotebookitembuttonbackground.svg");
  background-position: center top;
  background-repeat: no-repeat;
  background-size: 100% 200%;
  border-radius: 8px;
  box-sizing: border-box;
  display: inline-block;
  height: 78px;
  overflow: hidden;
  position: relative;
  width: 98px; }
  .addnotebookitembutton::after {
    background: url("images/icon-add-light1.svg") center center no-repeat;
    content: "";
    height: 40px;
    top: calc(50% - 20px);
    left: calc(50% - 20px);
    position: absolute;
    width: 40px;
    z-index: 1; }
  .addnotebookitembutton:hover, .addnotebookitembutton:focus {
    background-position: center bottom; }
  .addnotebookitembutton span {
    color: transparent;
    overflow: hidden;
    position: absolute;
    user-select: none; }

.audiobutton {
  background-image: url("images/smallbutton-bg.svg");
  color: transparent;
  height: 80px;
  overflow: hidden;
  position: relative;
  user-select: none;
  width: 80px;
  z-index: 2; }
  .audiobutton:hover, .audiobutton:focus {
    background: url("images/smallbutton-bg-active.svg") center center no-repeat; }
  .audiobutton::before {
    background-image: url("images/icon-volume-light1.svg");
    background-repeat: no-repeat;
    content: "";
    height: 32px;
    left: 24px;
    position: absolute;
    top: 24px;
    width: 32px; }

.byebutton {
  background-image: url("images/byebutton.svg");
  background-repeat: no-repeat;
  color: transparent;
  display: inline-block;
  height: 76px;
  overflow: hidden;
  position: relative;
  user-select: none;
  width: 212px; }
  .byebutton::after, .byebutton::before {
    background-repeat: no-repeat;
    content: "";
    display: inline-block;
    position: absolute; }
  .byebutton::after {
    background-image: url("images/byebutton-text.svg");
    background-position: center center;
    height: 48px;
    left: 28px;
    top: 14px;
    width: 99px; }
  .byebutton::before {
    background-image: url("images/byebutton-art.svg");
    height: 50px;
    right: 24px;
    top: 14px;
    width: 49px; }
  .byebutton:hover {
    background-image: url("images/byebutton-active.svg"); }

.closebutton {
  background: url("images/buttonframe-small-sprite.svg") no-repeat;
  background-size: 100% auto;
  color: transparent;
  display: block;
  height: 63px;
  overflow: hidden;
  position: relative;
  user-select: none;
  width: 83px;
  z-index: 2; }
  .closebutton:hover, .closebutton:focus {
    background-position: 0 -64px; }
  .closebutton:active {
    background-position: 0 -128px; }
    .closebutton:active::before {
      left: 22px;
      top: 20px; }
  .closebutton::before {
    background-image: url("images/icon-close-light1.svg");
    background-repeat: no-repeat;
    content: "";
    height: 32px;
    left: 29px;
    position: absolute;
    top: 13px;
    width: 32px; }
  .closebutton.closebutton-is-disabled {
    background-position: 0 -192px;
    pointer-events: none;
    user-select: none; }
    .closebutton.closebutton-is-disabled:active::before {
      left: 29px;
      top: 13px; }

.directionbutton {
  background-image: url("images/icon-forward-dark1.svg");
  color: transparent;
  height: 40px;
  overflow: hidden;
  position: relative;
  user-select: none;
  width: 40px; }
  .directionbutton::before {
    background-repeat: no-repeat;
    content: "";
    height: 40px;
    position: absolute;
    top: 28px;
    width: 40px; }
  .directionbutton.directionbutton-is-disabled, .directionbutton:disabled {
    opacity: 0.5;
    pointer-events: none; }

.directionbutton-backward {
  background-image: url("images/icon-back-dark1.svg"); }

.directionbutton-backwardlight1 {
  background-image: url("images/icon-back-light1.svg"); }

.directionbutton-forwardlight1 {
  background-image: url("images/icon-forward-light1.svg"); }

.directionbutton-forward::before {
  background-image: url("images/icon-forward-dark1.svg");
  left: 31px; }

.directionbutton-large {
  border: none;
  border-radius: 0;
  box-shadow: none; }
  .directionbutton-large::before {
    top: 0; }
  .directionbutton-large.directionbutton-backward::before {
    right: 0; }
  .directionbutton-large.directionbutton-forward::before {
    left: 0; }

.expandbutton {
  background-image: url("images/smallbutton-bg.svg");
  background-size: 100% 100%;
  color: transparent;
  height: 40px;
  overflow: hidden;
  position: relative;
  user-select: none;
  width: 40px;
  z-index: 2; }
  .expandbutton:hover, .expandbutton:focus {
    background: url("images/smallbutton-bg-active.svg") center center no-repeat;
    background-size: 100% 100%; }
  .expandbutton::before {
    background-image: url("images/icon-add-light1.svg");
    background-repeat: no-repeat;
    content: "";
    height: 24px;
    left: 8px;
    position: absolute;
    transform: rotate(5deg);
    transition: transform ease-in-out 0.5s;
    top: 8px;
    width: 24px;
    will-change: transform; }
  .expandbutton.expandbutton-is-active::before {
    transform: rotate(412deg); }

.nextbutton {
  background: url("images/buttonframe-small-sprite.svg") no-repeat;
  background-size: 100% auto;
  color: transparent;
  height: 64px;
  overflow: hidden;
  position: relative;
  user-select: none;
  width: 83px;
  z-index: 2; }
  .nextbutton:hover, .nextbutton:focus {
    background-position: 0 -64px; }
  .nextbutton:active {
    background-position: 0 -128px; }
    .nextbutton:active::before {
      left: 22px;
      top: 20px; }
  .nextbutton::before {
    background-image: url("images/icon-forward-light1.svg");
    background-repeat: no-repeat;
    content: "";
    height: 32px;
    left: 29px;
    position: absolute;
    top: 13px;
    width: 32px; }
  .nextbutton.nextbutton-is-disabled {
    background-position: 0 -192px;
    pointer-events: none;
    user-select: none; }
    .nextbutton.nextbutton-is-disabled:active::before {
      left: 29px;
      top: 13px; }

.playlistbutton {
  background-repeat: no-repeat;
  color: transparent;
  display: inline-block;
  height: 80px;
  overflow: hidden;
  position: relative;
  user-select: none;
  width: 218px; }
  .playlistbutton::after, .playlistbutton::before {
    background-repeat: no-repeat;
    content: "";
    display: inline-block;
    position: absolute; }
  .playlistbutton::after {
    background-position: center center;
    height: 60px;
    right: 16px;
    top: 8px;
    width: 109px; }
  .playlistbutton::before {
    height: 60px;
    left: 16px;
    top: 8px;
    width: 67px; }

.playlistbutton-draw {
  background-image: url("images/playlistbutton-bg-draw.svg");
  width: 219px; }
  .playlistbutton-draw::after {
    background-image: url("images/playlistbutton-drawtext.svg");
    right: 24px; }
  .playlistbutton-draw::before {
    background-image: url("images/playlistbutton-drawart.svg"); }
  .playlistbutton-draw.playlistbutton-is-done {
    background-image: url("images/playlistbutton-bg-draw-done.svg"); }
    .playlistbutton-draw.playlistbutton-is-done::after {
      background-image: url("images/playlistbutton-drawtext-done.svg"); }

.playlistbutton-make {
  background-image: url("images/playlistbutton-bg-make.svg");
  width: 207px; }
  .playlistbutton-make::after {
    background-image: url("images/playlistbutton-maketext.svg"); }
  .playlistbutton-make::before {
    background-image: url("images/playlistbutton-makeart.svg"); }
  .playlistbutton-make.playlistbutton-is-done {
    background-image: url("images/playlistbutton-bg-make-done.svg"); }
    .playlistbutton-make.playlistbutton-is-done::after {
      background-image: url("images/playlistbutton-maketext-done.svg"); }

.playlistbutton-play {
  background-image: url("images/playlistbutton-bg-play.svg");
  width: 219px; }
  .playlistbutton-play::after {
    background-image: url("images/playlistbutton-playtext.svg");
    right: 8px; }
  .playlistbutton-play::before {
    background-image: url("images/playlistbutton-playart.svg");
    width: 88px; }
  .playlistbutton-play.playlistbutton-is-done {
    background-image: url("images/playlistbutton-bg-play-done.svg"); }
    .playlistbutton-play.playlistbutton-is-done::after {
      background-image: url("images/playlistbutton-playtext-done.svg"); }

.playlistbutton-play-analog {
  background-image: url("images/playlistbutton-bg-play.svg");
  width: 219px; }
  .playlistbutton-play-analog::after {
    background-image: url("images/playlistbutton-playtext.svg");
    right: 8px; }
  .playlistbutton-play-analog::before {
    background-image: url("images/icon-activityanalog.svg");
    left: 32px;
    top: 10px;
    width: 88px; }
  .playlistbutton-play-analog.playlistbutton-is-done {
    background-image: url("images/playlistbutton-bg-play-done.svg"); }
    .playlistbutton-play-analog.playlistbutton-is-done::after {
      background-image: url("images/playlistbutton-playtext-done.svg"); }

.playlistbutton-read {
  background-image: url("images/playlistbutton-bg-read.svg");
  width: 182px; }
  .playlistbutton-read::after {
    background-image: url("images/playlistbutton-readtext.svg");
    right: 16px; }
  .playlistbutton-read::before {
    background-image: url("images/playlistbutton-readart.svg");
    left: 12px; }
  .playlistbutton-read.playlistbutton-is-done {
    background-image: url("images/playlistbutton-bg-read-done.svg"); }
    .playlistbutton-read.playlistbutton-is-done::after {
      background-image: url("images/playlistbutton-readtext-done.svg"); }

.playlistbutton-watch {
  background-image: url("images/playlistbutton-bg-watch.svg");
  width: 221px; }
  .playlistbutton-watch::after {
    background-image: url("images/playlistbutton-watchtext.svg");
    right: 24px; }
  .playlistbutton-watch::before {
    background-image: url("images/playlistbutton-watchart.svg"); }
  .playlistbutton-watch.playlistbutton-is-done {
    background-image: url("images/playlistbutton-bg-watch-done.svg"); }
    .playlistbutton-watch.playlistbutton-is-done::after {
      background-image: url("images/playlistbutton-watchtext-done.svg"); }

.previousbutton {
  background: url("images/buttonframe-small-sprite.svg") no-repeat;
  background-size: 100% auto;
  color: transparent;
  height: 63px;
  overflow: hidden;
  position: relative;
  user-select: none;
  width: 83px;
  z-index: 2; }
  .previousbutton:hover, .previousbutton:focus {
    background-position: 0 -64px; }
  .previousbutton:active {
    background-position: 0 -128px; }
    .previousbutton:active::before {
      left: 22px;
      top: 20px; }
  .previousbutton::before {
    background-image: url("images/icon-back-light1.svg");
    background-repeat: no-repeat;
    content: "";
    height: 32px;
    left: 29px;
    position: absolute;
    top: 13px;
    width: 32px; }
  .previousbutton.previousbutton-is-disabled {
    background-position: 0 -192px;
    pointer-events: none;
    user-select: none; }
    .previousbutton.previousbutton-is-disabled:active::before {
      left: 29px;
      top: 13px; }

.printbutton {
  background-color: #418fde;
  border-radius: 40px;
  border: solid 3px black;
  box-shadow: inset 0 4px 0 0 #00dcf8;
  box-sizing: border-box;
  color: #fff;
  display: inline-block;
  font-size: 24px;
  font-weight: bold;
  height: 80px;
  line-height: 24px;
  overflow: hidden;
  padding-left: 85px;
  padding-right: 32px;
  position: relative;
  text-shadow: 0 2px 0 #231f20; }
  .printbutton::before {
    background-image: url("images/button-printart.svg");
    background-repeat: no-repeat;
    content: '';
    display: block;
    height: 55px;
    left: 24px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 52px; }

.textbutton {
  color: #fff;
  font-family: Bangers;
  font-weight: normal;
  letter-spacing: 1.38px;
  text-shadow: 2px 3px 1px #231F20;
  font-size: 24px;
  line-height: 24px;
  background: #418fde;
  border: 4px solid #231f20;
  border-radius: 100px;
  box-shadow: inset 0 4px 0 0 #00dcf8;
  color: #fff;
  display: inline-block;
  padding: 24px; }

.character {
  display: block;
  height: 200px;
  margin: auto;
  width: auto; }

.charactercarditem {
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0 0 0 8px #418fde, inset 0 0 0 4px #231f20;
  box-sizing: border-box;
  display: block;
  overflow: hidden;
  padding: 16px;
  position: relative;
  transition: border 0.25s ease-in-out, box-shadow 0.25s ease-in-out;
  width: 148px;
  z-index: 1; }
  .charactercarditem img {
    display: block;
    height: 70px;
    margin: auto;
    position: relative;
    width: auto;
    z-index: 1; }
  .charactercarditem:hover, .charactercarditem:focus {
    box-shadow: 0 0 0 12px #2f72f7, inset 0 0 0 4px #231f20; }
  .charactercarditem:focus {
    outline: none; }
  .charactercarditem:active, .charactercarditem.charactercarditem-is-active {
    box-shadow: 0 0 0 4px #ffa300, inset 0 0 0 4px #231f20; }
  .charactercarditem .charactercarditem-x-username {
    font-family: Arial;
    font-size: 16px;
    line-height: 24px;
    display: block;
    margin-top: 16px; }

.charactercarditem-large {
  padding: 32px 8px; }
  .charactercarditem-large img {
    height: 126px; }
  .charactercarditem-large .charactercarditem-x-username {
    margin-top: 24px; }

.charactercarditem-small .charactercarditem-x-username {
  bottom: 16px; }

.circleimage {
  border: solid 14px #418fde;
  border-radius: 100%;
  box-sizing: border-box;
  height: 154px;
  overflow: hidden;
  position: relative;
  width: 154px; }
  .circleimage img {
    position: relative;
    z-index: -1;
    min-width: 128px; }
  .circleimage::before {
    border-radius: 100%;
    bottom: 0;
    box-shadow: inset 0 0 0 4px #231f20;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0; }
  .circleimage.circleimage-isdone {
    border: solid 16px #97D700; }

.circleimage-large {
  border: solid 10px #fff;
  box-shadow: inset 0 0 0 8px #231f20;
  height: 280px;
  width: 280px; }
  .circleimage-large img {
    min-width: 260px; }

.circleimage-medium {
  border: solid 10px #fff;
  box-shadow: inset 0 0 0 8px #231f20;
  height: 208px;
  width: 208px; }
  .circleimage-medium img {
    min-width: 180px; }

.circleimage-small {
  border: solid 10px #fff;
  box-shadow: inset 0 0 0 8px #231f20;
  height: 144px;
  width: 144px;
  z-index: 1; }
  .circleimage-small img {
    min-width: 120px; }
  .circleimage-small::before {
    box-shadow: inset 0 0 0 8px #231f20; }

.collapse .collapse-x-headline {
  position: relative; }
  .collapse .collapse-x-headline + .collapse-x-target {
    margin-top: 16px; }

.collapse .collapse-x-target {
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  user-select: none; }
  .collapse .collapse-x-target div {
    opacity: 0;
    transform: translateY(-100%);
    transition: opacity ease-in-out 0.25s, transform ease-in-out 0.25s;
    will-change: opacity, transform; }

.collapse .collapse-x-trigger {
  position: absolute;
  right: 0;
  top: 0; }

.collapse.collapse-is-active .collapse-x-target {
  position: relative;
  pointer-events: auto; }
  .collapse.collapse-is-active .collapse-x-target div {
    opacity: 1;
    transform: none; }

.emptymessage {
  background-color: #fff;
  border: 8px dashed #e1e0e1;
  box-sizing: border-box;
  padding: 34px 24px;
  text-align: center; }
  .emptymessage h2 {
    font-family: Arial Black;
    font-size: 32px;
    line-height: 40px;
    color: #757575;
    margin-bottom: 8px; }
  .emptymessage p {
    font-family: Arial;
    font-size: 16px;
    line-height: 24px; }

.emptymessage-light {
  border-color: #fff; }
  .emptymessage-light h3,
  .emptymessage-light p {
    color: #fff;
    text-shadow: 2px 2px 0 #231f20; }

.emptymessage-long {
  padding-left: 64px;
  padding-right: 64px; }

.favorite {
  background-image: url("images/favorite.svg");
  color: transparent;
  height: 80px;
  overflow: hidden;
  width: 80px;
  user-select: none; }
  .favorite:hover, .favorite:focus {
    background-position-x: -80px; }
  .favorite:active {
    background-position-x: -160px; }
  .favorite.favorite-is-populated {
    background-position: -320px; }
  .favorite.favorite-is-populated:hover, .favorite.favorite-is-populated:focus {
    background-position: -400px; }
  .favorite.favorite-is-populated:active {
    background-position: -480px; }
  .favorite[disabled], .favorite.favorite-is-disabled {
    background-position-x: -240px; }

.forminput {
  font-family: Arial Black;
  font-size: 24px;
  line-height: 32px;
  width: 704px; }
  .forminput > div {
    align-items: center;
    display: flex;
    min-height: 80px;
    position: relative; }
  .forminput input {
    background-color: #fff;
    border-radius: 70px;
    box-shadow: inset 0 0 0 8px #757575, 0 0 18px rgba(35, 31, 32, 0.5);
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    padding: 24px 48px 24px 124px;
    transition: box-shadow ease-in-out 0.25s;
    width: 100%;
    will-change: box-shadow;
    z-index: 1; }
    .forminput input:focus {
      box-shadow: inset 0 0 0 8px #418fde, 0 0 18px rgba(35, 31, 32, 0.5);
      outline: none;
      transition-duration: 0.25s; }
    .forminput input:-moz-placeholder, .forminput input::-moz-placeholder, .forminput input:-ms-input-placeholder, .forminput input::-ms-input-placeholder, .forminput input::placeholder {
      color: #757575; }
  .forminput label {
    align-items: center;
    display: flex;
    font-size: inherit;
    font-weight: inherit;
    justify-content: flex-end;
    line-height: inherit;
    position: absolute;
    text-align: right;
    top: 50%;
    transform: translateY(-50%);
    user-select: none;
    width: 116px;
    z-index: 2; }
  .forminput .forminput-x-errordark,
  .forminput .forminput-x-errorlight {
    font-family: Arial;
    font-size: 24px;
    font-weight: normal;
    line-height: 32px;
    margin-top: 16px;
    text-align: center;
    z-index: 1; }
  .forminput .forminput-x-errordark {
    color: #231f20; }
  .forminput .forminput-x-errorlight {
    color: #fff; }
  .forminput.forminput-is-error label {
    width: 160px; }
    .forminput.forminput-is-error label::before {
      background-image: url("images/icon-error.svg");
      background-repeat: no-repeat;
      content: '';
      height: 40px;
      left: 20px;
      margin-right: 12px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 40px; }
  .forminput.forminput-is-error input {
    box-shadow: inset 0 0 0 8px #cb333b, 0 0 18px #231f20;
    color: #cb333b;
    padding-left: 168px; }
    .forminput.forminput-is-error input:-moz-placeholder, .forminput.forminput-is-error input::-moz-placeholder, .forminput.forminput-is-error input:-ms-input-placeholder, .forminput.forminput-is-error input::-ms-input-placeholder, .forminput.forminput-is-error input::placeholder {
      color: #757575; }

.forminput-signin input {
  width: 440px; }

.forminput-withborder input {
  box-shadow: inset 0 0 0 8px #231f20; }
  .forminput-withborder input:focus {
    box-shadow: inset 0 0 0 8px #418fde; }

.forminput-withborder.forminput-is-error {
  box-shadow: none; }
  .forminput-withborder.forminput-is-error input {
    box-shadow: inset 0 0 0 8px #cb333b; }
    .forminput-withborder.forminput-is-error input:focus {
      box-shadow: inset 0 0 0 8px #418fde; }

.iconheader {
  font-family: Arial Black;
  font-size: 32px;
  line-height: 40px;
  padding-top: 66px;
  position: relative;
  text-align: center; }
  .iconheader::before {
    background: center center no-repeat;
    background-size: contain;
    content: '';
    display: block;
    height: 58px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%; }

.iconheader-user::before {
  background-image: url("images/iconheader-user.svg"); }

.iconheader-notebook::before {
  background-image: url("images/iconheader-notebook.svg"); }

.iconheader-playertwo::before {
  background-image: url("images/iconheader-playertwo.svg"); }

.iconheader-superpowers::before {
  background-image: url("images/iconheader-superpowers.svg"); }

.iconheader-user::before {
  background-image: url("images/icon-user-dark1.svg");
  background-size: 64px 64px;
  height: 64px; }

.iconheader-notebook {
  padding-top: 60px; }
  .iconheader-notebook::before {
    background-image: url("images/icon-book-dark1.svg");
    background-size: 52px 52px;
    height: 52px; }

.iconheader-playertwo::before {
  background-image: url("images/icon-playertwo-dark1.svg");
  background-size: 64px 64px;
  height: 64px; }

.iconheader-superpowers {
  padding-top: 60px; }
  .iconheader-superpowers::before {
    background-image: url("images/icon-star-dark1.svg");
    background-size: 52px 52px;
    height: 52px; }

.icon {
  box-sizing: border-box;
  color: transparent;
  display: inline-block;
  height: 64px;
  position: relative;
  user-select: none;
  width: 64px; }
  .icon::before {
    background-position: center center;
    background-repeat: no-repeat;
    position: absolute;
    content: "";
    height: 64px;
    left: 0;
    top: 0;
    width: 64px; }

.icon-bolt::before {
  background-image: url("images/icon-bolt.svg"); }

.icon-check::before {
  background-image: url("images/icon-check.svg"); }

.iconlink {
  font-family: Arial;
  font-size: 24px;
  font-weight: normal;
  line-height: 32px;
  color: #418fde;
  height: auto;
  padding-left: 48px;
  position: relative; }
  .iconlink::before {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    content: '';
    display: block;
    height: 32px;
    left: 0;
    position: absolute;
    top: 0;
    width: 32px; }
  .iconlink .iconlink-x-status {
    background-image: url("images/iconlink-status-complete.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    color: transparent;
    height: 18px;
    left: 20px;
    position: absolute;
    top: 0;
    user-select: none;
    width: 24px; }

.iconlink-book::before {
  background-image: url("images/icon-book-dark1.svg"); }

.iconlink-chesspiece::before {
  background-image: url("images/icon-chesspiece-dark1.svg"); }

.iconlink-game::before {
  background-image: url("images/icon-game-dark1.svg"); }

.iconlink-pencil::before {
  background-image: url("images/icon-pencil-dark1.svg"); }

.iconlink-play::before {
  background-image: url("images/icon-play-dark1.svg"); }

.iconlink-scissors::before {
  background-image: url("images/icon-scissors-dark1.svg"); }

.iconnumber {
  background: none;
  border: 0;
  color: transparent;
  display: inline-block;
  height: 96px;
  outline: none;
  padding: 0;
  position: relative;
  user-select: none;
  width: 34px; }
  .iconnumber::before {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    content: '';
    display: block;
    height: 96px;
    left: 0;
    position: absolute;
    top: 0;
    width: 34px; }

.iconnumber-0::before {
  background-image: url("images/iconnumber-0.svg"); }

.iconnumber-1::before {
  background-image: url("images/iconnumber-1.svg"); }

.iconnumber-2::before {
  background-image: url("images/iconnumber-2.svg"); }

.iconnumber-3::before {
  background-image: url("images/iconnumber-3.svg"); }

.iconnumber-4::before {
  background-image: url("images/iconnumber-4.svg"); }

.iconnumber-5::before {
  background-image: url("images/iconnumber-5.svg"); }

.iconnumber-6::before {
  background-image: url("images/iconnumber-6.svg"); }

.iconnumber-7::before {
  background-image: url("images/iconnumber-7.svg"); }

.iconnumber-8::before {
  background-image: url("images/iconnumber-8.svg"); }

.iconnumber-9::before {
  background-image: url("images/iconnumber-9.svg"); }

.logo {
  background: url("images/logo.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  color: transparent;
  height: 230px;
  overflow: hidden;
  user-select: none;
  width: 558px;
  z-index: 1; }

.logo-medium {
  height: 128px;
  width: 310px; }

.logo-small,
.logo-smallresponsive {
  height: 96px;
  width: 242px; }

.logo-smallcolor {
  height: 64px;
  width: 155px; }

.logo-smallest {
  height: 72px;
  width: 142px; }

@media (max-width: 805px) {
  .logo-smallresponsive {
    height: 44px;
    width: 109px; } }

.logoutlink {
  background: url("images/buttonframe-small.svg") no-repeat 0 0;
  color: transparent;
  display: inline-block;
  overflow: hidden;
  height: 64px;
  position: relative;
  width: 83px;
  z-index: 2; }
  .logoutlink:hover, .logoutlink:focus {
    background-image: url("images/buttonframe-small-hover.svg"); }
  .logoutlink:active {
    background-image: url("images/buttonframe-small-active.svg"); }
    .logoutlink:active::before {
      left: 22px;
      top: 20px; }
  .logoutlink::before {
    background: url("images/icon-logout-light1.svg") no-repeat center center;
    content: '';
    height: 32px;
    left: 29px;
    position: absolute;
    top: 13px;
    width: 32px; }

.modal {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background-color: rgba(35, 31, 32, 0.6);
  opacity: 0;
  overflow-y: auto;
  pointer-events: none;
  transition: opacity 0.25s ease-in-out; }
  .modal .modal-x-next,
  .modal .modal-x-previous {
    position: absolute;
    top: 16px; }
  .modal .modal-x-box {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 8px;
    flex: 1 1 100%;
    margin: 16px;
    max-width: 850px;
    min-height: 0;
    overflow-y: auto;
    padding: 8px;
    width: calc(100% - 32px); }
  .modal .modal-x-header {
    align-self: stretch;
    align-items: center;
    background-color: #fff;
    box-sizing: border-box;
    display: flex;
    flex: 0 0 auto;
    padding: 28px 115px; }
    .modal .modal-x-header > * {
      flex: 0 0 auto; }
      .modal .modal-x-header > *:not(:last-child) {
        margin-right: 16px; }
    .modal .modal-x-header h2 {
      font-family: CCKillJoy;
      font-size: 40px;
      line-height: 40px;
      flex: 1 1 100%;
      overflow: hidden;
      text-align: center;
      text-overflow: ellipsis;
      white-space: nowrap; }
  .modal .modal-x-iframe {
    background-color: #231f20;
    display: block;
    flex: 1 1 100%;
    width: 100%; }
  .modal .modal-x-image {
    display: block;
    flex: 0 1 100%;
    min-height: 0;
    min-width: 0;
    object-fit: cover;
    width: 100%; }
  .modal .modal-x-main {
    flex: 1 1 100%;
    overflow-y: auto;
    width: 100%; }
  .modal .modal-x-next {
    right: 16px; }
  .modal .modal-x-notice {
    background-color: #fff;
    border-radius: 4px;
    box-sizing: border-box;
    left: 50%;
    max-height: 100%;
    overflow-y: auto;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 540px;
    z-index: 2; }
  .modal .modal-x-particles {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1; }
  .modal .modal-x-previous {
    left: 16px; }
  .modal.modal-is-active {
    opacity: 1;
    pointer-events: auto; }
  .modal.modal-is-fullscreen * {
    display: none; }
  .modal.modal-is-fullscreen .modal-x-iframe {
    display: block; }
  .modal.modal-is-fullscreen .modal-x-box {
    border-radius: 0;
    display: flex;
    margin: 0;
    max-width: 100%;
    padding: 0;
    width: 100%; }

@keyframes confetti-slow {
  0% {
    transform: translate3d(0, 0, 0) rotateX(0) rotateY(0); }
  100% {
    transform: translate3d(25px, 105vh, 0) rotateX(360deg) rotateY(180deg); } }

@keyframes confetti-medium {
  0% {
    transform: translate3d(0, 0, 0) rotateX(0) rotateY(0); }
  100% {
    transform: translate3d(100px, 105vh, 0) rotateX(100deg) rotateY(360deg); } }

@keyframes confetti-fast {
  0% {
    transform: translate3d(0, 0, 0) rotateX(0) rotateY(0); }
  100% {
    transform: translate3d(-50px, 105vh, 0) rotateX(10deg) rotateY(250deg); } }

.particles .particles-x-container {
  perspective: 700px;
  position: absolute;
  overflow: hidden;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

.particles .particle-x-particle {
  position: absolute;
  z-index: 1;
  top: -10px;
  will-change: transform; }
  .particles .particle-x-particleanimationslow {
    animation: confetti-slow 2.25s linear 1 forwards;
    border-radius: 100%; }
  .particles .particle-x-particleanimationmedium {
    animation: confetti-medium 1.75s linear 1 forwards; }
  .particles .particle-x-particleanimationfast {
    animation: confetti-fast 1.25s linear 1 forwards; }

.avatar {
  display: inline-block;
  overflow: hidden;
  width: 128px; }
  .avatar .avatar-x-image {
    align-items: center;
    display: flex;
    justify-content: center;
    height: 80px;
    position: relative; }
    .avatar .avatar-x-image img {
      height: 100%;
      width: auto; }
  .avatar .avatar-x-playersname {
    font-family: Arial;
    font-size: 16px;
    line-height: 24px;
    color: #fff;
    font-weight: bold;
    text-align: center; }

.navitem {
  display: block;
  height: 118px;
  overflow: hidden;
  position: relative;
  width: 160px; }
  .navitem::after, .navitem::before {
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    content: '';
    display: inline-block;
    height: 48px;
    position: absolute;
    width: 48px; }
  .navitem::after {
    height: 114px;
    left: 0;
    overflow: hidden;
    top: 0;
    width: 160px;
    z-index: 1; }
  .navitem::before {
    left: 50%;
    transform: translateX(-50%);
    top: 18px;
    z-index: 2; }
  .navitem:focus::after, .navitem:hover::after {
    background-position: 0 50%; }
  .navitem:active {
    transform: translate(-3px, 4px); }
  .navitem .navitem-x-label {
    font-family: CCKillJoy;
    font-size: 20px;
    line-height: 24px;
    bottom: 12px;
    color: #fff;
    display: inline-block;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    user-select: none;
    width: 128px;
    z-index: 2; }
  .navitem .navitem-x-notice {
    background-color: #97D700;
    border-radius: 100%;
    bottom: 32px;
    color: transparent;
    display: none;
    height: 16px;
    position: absolute;
    right: 8px;
    user-select: none;
    width: 16px;
    z-index: 2; }
  .navitem.navitem-is-active::after {
    background-position: 0 100%; }
  .navitem.navitem-is-active:active {
    transform: none; }
  .navitem.navitem-is-done .navitem-x-notice {
    display: block; }

.navitem-play::after {
  background-image: url("images/buttonframe-mainnav-left.svg");
  background-position: 0 0;
  background-size: 100%; }

.navitem-play::before {
  background-image: url("images/icon-sparks-light1.svg"); }

.navitem-play.navitem-is-active::after {
  background-image: url("images/buttonframe-mainnav-left.svg"); }

.navitem-play.navitem-is-active::before {
  background-image: url("images/icon-sparks-dark1.svg"); }

.navitem-play.navitem-is-active span {
  color: #231f20; }

.navitem-notebook::after {
  background-image: url("images/buttonframe-mainnav-right.svg");
  background-position: 0 0;
  background-size: 100%; }

.navitem-notebook::before {
  background-image: url("images/icon-marker-light1.svg"); }

.navitem-notebook.navitem-is-active::after {
  background-image: url("images/buttonframe-mainnav-right.svg"); }

.navitem-notebook.navitem-is-active::before {
  background-image: url("images/icon-marker-dark1.svg"); }

.navitem-notebook.navitem-is-active span {
  color: #231f20; }

.navitem-powers::after {
  background-image: url("images/buttonframe-mainnav-middle.svg");
  background-position: 0 0;
  background-size: 100%; }

.navitem-powers::before {
  background-image: url("images/icon-complete-light1.svg"); }

.navitem-powers.navitem-is-active::before {
  background-image: url("images/icon-complete-dark1.svg"); }

.navitem-powers.navitem-is-active span {
  color: #231f20; }

.notebookdetail {
  border-radius: 24px;
  border: solid 8px #fff;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  width: 640px; }
  .notebookdetail img {
    display: block;
    height: auto;
    position: relative;
    width: 100%;
    z-index: 1; }
  .notebookdetail::before {
    border-radius: 16px;
    bottom: 0;
    box-shadow: inset 0 0 0 8px #231f20;
    content: '';
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2; }

.notebookdetail-invertedbordercolor {
  border: solid 8px #231f20; }
  .notebookdetail-invertedbordercolor::before {
    box-shadow: inset 0 0 0 8px #fff; }

.notebookdetail-empty {
  background-color: #fff;
  border: solid 8px #fff;
  height: 480px;
  width: 640px;
  align-items: center;
  display: flex;
  justify-content: center; }

.notebookdetail-small {
  width: 540px; }

.notebookitem {
  border-radius: 4px;
  box-shadow: 0 0 0 4px #231f20, 0 0 0 8px #fff;
  box-sizing: border-box;
  margin: 8px;
  overflow: hidden;
  position: relative; }
  .notebookitem img {
    display: block;
    height: auto;
    position: relative;
    width: 100%; }
  .notebookitem:hover, .notebookitem:focus, .notebookitem.notebookitem-is-active {
    box-shadow: 0 0 0 4px #ffa300, 0 0 0 8px #fff; }

.passcodebutton {
  font-family: CCKillJoy;
  font-size: 20px;
  line-height: 24px;
  background-color: #418fde;
  border-radius: 16px;
  box-shadow: 0 0 0 12px #418fde;
  color: #fff;
  height: 80px;
  transition: background-color 0.25s ease-in-out, box-shadow 0.25s ease-in-out;
  width: 80px;
  will-change: background-color, box-shadow;
  z-index: 0; }
  .passcodebutton:hover, .passcodebutton:focus {
    background-color: #2f72f7;
    box-shadow: 0 0 0 16px #2f72f7;
    outline: none; }
  .passcodebutton:active {
    background-color: #ffa300;
    box-shadow: 0 0 0 8px #ffa300; }
  .passcodebutton[disabled] {
    background-color: #757575;
    box-shadow: 0 0 0 12px #757575; }
    .passcodebutton[disabled]:hover {
      cursor: not-allowed; }

.passcodeselect {
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0 0 0 4px #231f20, 0 0 0 12px #418fde;
  height: 80px;
  overflow: hidden;
  transition: box-shadow 0.25s ease-in-out;
  width: 80px;
  will-change: box-shadow;
  z-index: 1; }
  .passcodeselect img {
    display: block;
    position: relative; }
  .passcodeselect:hover, .passcodeselect:focus {
    box-shadow: 0 0 0 4px #231f20, 0 0 0 16px #2f72f7;
    outline: none; }
  .passcodeselect:active {
    box-shadow: 0 0 0 4px #231f20, 0 0 0 8px #ffa300; }

.passcodeselected {
  padding-bottom: 28px;
  position: relative;
  width: 104px;
  z-index: 1; }
  .passcodeselected::after {
    background-color: #231f20;
    border-radius: 8px;
    bottom: 0;
    content: '';
    display: block;
    height: 8px;
    left: 0;
    position: absolute;
    transition: background-color 0.25s ease-in-out;
    width: 100%;
    will-change: background-color; }
  .passcodeselected .passcodeselected-x-image {
    background-color: #e1e0e1;
    border-radius: 16px;
    box-shadow: 0 0 0 4px #9b9b9b;
    height: 80px;
    margin: 0 auto;
    overflow: hidden;
    transition: box-shadow 0.25s ease-in-out;
    width: 80px;
    will-change: box-shadow; }
    .passcodeselected .passcodeselected-x-image img {
      display: block; }
  .passcodeselected.passcodeselected-is-error::after {
    background-color: #cb333b; }
  .passcodeselected.passcodeselected-is-error .passcodeselected-x-image {
    box-shadow: 0 0 0 4px #cb333b; }
  .passcodeselected.passcodeselected-is-selected .passcodeselected-x-image {
    box-shadow: 0 0 0 4px #231f20; }

.qrscan {
  background-color: #231f20;
  height: 354px;
  position: relative;
  width: 352px; }
  .qrscan button {
    background-image: url("images/icon-camera-swap-light1.svg");
    background-size: cover;
    bottom: 24px;
    color: transparent;
    display: block;
    height: 64px;
    left: 50%;
    overflow: hidden;
    position: absolute;
    transform: translateX(-50%);
    user-select: none;
    width: 64px; }
  .qrscan canvas {
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%; }

.qrscan-flipx {
  transform: scaleX(-1); }

.qrscan-unsuccessful::after {
  background-image: url("images/icon-close.svg");
  height: 64px;
  left: 50%;
  position: absolute;
  width: 64px;
  top: 50%;
  transform: translate(-50%, -50%); }

.quicklinks a,
.quicklinks button {
  background-color: #CDCDCD;
  color: #231f20;
  display: block;
  left: 8px;
  opacity: 0;
  position: fixed;
  padding: 8px;
  pointer-events: none;
  top: 8px;
  transition: opacity 0.25s ease-in-out;
  z-index: 3; }
  .quicklinks a:focus,
  .quicklinks button:focus {
    opacity: 1;
    pointer-events: auto; }

.stack {
  box-sizing: border-box;
  display: flex;
  padding-left: 72px;
  padding-right: 72px;
  width: 100%; }
  .stack .stack-x-item {
    flex: 1 1 100%;
    height: 144px;
    overflow: visible;
    position: relative; }
    .stack .stack-x-item > * {
      box-shadow: 2px 3px 1px #231F20;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%); }

.textblock {
  font-family: Arial;
  font-size: 24px;
  font-weight: normal;
  line-height: 32px; }
  .textblock h3 {
    font-family: Arial Black;
    font-size: 32px;
    line-height: 40px; }
  .textblock h4 {
    font-family: Arial Black;
    font-size: 24px;
    line-height: 32px; }
  .textblock h5 {
    font-family: Arial;
    font-size: 16px;
    font-weight: bold;
    line-height: 24px; }
  .textblock a {
    color: #418fde; }
  .textblock ol {
    list-style-type: decimal; }
  .textblock ul {
    list-style-type: disc; }
  .textblock .textblock-x-stack {
    align-items: flex-start;
    display: flex;
    flex-direction: column; }
    .textblock .textblock-x-stack > *:not(:first-child) {
      margin-top: 8px; }
    .textblock .textblock-x-stack > *:first-child {
      margin-top: 0; }
  .textblock .textblock-x-textalign-center {
    text-align: center; }
  .textblock .textblock-x-h1 {
    font-family: Arial Black;
    font-size: 32px;
    line-height: 40px; }

.toggle {
  border-radius: 500px;
  box-shadow: 0 0 0 1px #fff inset;
  display: flex; }

.toggleitem {
  border-radius: 500px;
  color: #fff;
  display: block;
  font-size: 16px;
  line-height: 24px;
  padding: 8px 16px; }
  .toggleitem.toggleitem-is-active {
    background-color: #fff;
    color: #231f20; }

.u-printable {
  display: none; }
  .u-printable .u-printable-x-title {
    font-family: Arial Black;
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 32px;
    margin-top: 32px;
    text-align: center; }
  .u-printable .u-printable-x-imagescontainer {
    display: block;
    width: 992px;
    margin-right: 24px;
    margin-left: 24px; }
  .u-printable .u-printable-x-images {
    display: grid;
    grid-template-columns: 480px 480px;
    grid-gap: 8px; }
    .u-printable .u-printable-x-images img {
      width: 480px;
      page-break-inside: avoid;
      vertical-align: bottom; }

@media print {
  .u-printable-hide {
    display: none; }
  .u-printable {
    display: block; } }

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.wrapper-light {
  background-color: #e1e0e1; }

.wrapper-lift {
  background: #fff;
  border: 8px solid #fff;
  box-shadow: 0 2px 8px 0 #231f20; }

.l-banneractivitytitle {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 64px;
  padding-top: 64px;
  position: relative;
  max-width: 540px;
  text-align: center; }
  .l-banneractivitytitle > *:not([type="button"]) {
    max-width: 100%; }
  .l-banneractivitytitle .l-banneractivitytitle-x-alert {
    display: flex;
    margin-bottom: 16px;
    width: 190px; }
    .l-banneractivitytitle .l-banneractivitytitle-x-alert p {
      margin-left: 40px; }

.l-bannerplaytitle {
  padding-bottom: 106px;
  padding-top: 80px;
  margin: auto;
  max-width: 540px; }
  .l-bannerplaytitle .l-bannerplaytitle-x-image {
    padding-bottom: 32px; }

.l-bannerprogress {
  display: flex; }
  .l-bannerprogress .l-bannerprogress-x-image {
    flex: 0 0 auto;
    margin-right: 32px; }
  .l-bannerprogress .l-bannerprogress-x-text p {
    margin-bottom: 8px;
    margin-top: 8px; }
    .l-bannerprogress .l-bannerprogress-x-text p:first-child {
      margin-top: 10px; }
    .l-bannerprogress .l-bannerprogress-x-text p:last-child {
      margin-bottom: 0; }
  .l-bannerprogress .l-bannerprogress-x-text h2 {
    margin-bottom: 8px; }

.l-bannersuperpowerheader {
  text-align: center; }
  .l-bannersuperpowerheader > div {
    align-items: center;
    display: flex;
    justify-content: center; }
    .l-bannersuperpowerheader > div:not(:last-child) {
      padding-bottom: 24px; }
    .l-bannersuperpowerheader > div > nav:first-of-type {
      margin-right: 80px; }
    .l-bannersuperpowerheader > div > nav:last-of-type {
      margin-left: 80px; }
  .l-bannersuperpowerheader .l-bannersuperpowerheader-x-subheader {
    padding-top: 8px; }

.l-bannersuperpowerheader-nobuttons {
  padding-top: 0; }

.l-gridfour {
  display: flex;
  flex-wrap: wrap;
  margin: 18px auto 0;
  width: 705px; }
  .l-gridfour > * {
    box-sizing: border-box;
    width: 25%; }
    .l-gridfour > *:not(:last-child) {
      margin-bottom: 16px; }
    .l-gridfour > *:nth-child(4n+1) {
      padding-right: 6px; }
    .l-gridfour > *:nth-child(4n+2) {
      padding-left: 2px;
      padding-right: 4px; }
    .l-gridfour > *:nth-child(4n+3) {
      padding-left: 4px;
      padding-right: 2px; }
    .l-gridfour > *:nth-child(4n+4) {
      padding-left: 6px; }

.l-gridfour-largegutter > *:not(:last-child) {
  margin-bottom: 16px; }

.l-gridfour-largegutter > *:nth-child(4n+1) {
  padding-right: 12px; }

.l-gridfour-largegutter > *:nth-child(4n+2) {
  padding-left: 4px;
  padding-right: 8px; }

.l-gridfour-largegutter > *:nth-child(4n+3) {
  padding-left: 8px;
  padding-right: 4px; }

.l-gridfour-largegutter > *:nth-child(4n+4) {
  padding-left: 12px; }

.l-centerhorizontal {
  align-items: center;
  display: flex;
  flex-direction: column; }

.l-charactercarditem {
  margin: 8px; }

.l-choosecharacter {
  align-items: center;
  display: flex;
  flex-direction: column; }

.l-circlelist {
  display: flex;
  flex-wrap: wrap;
  margin: 28px auto 32px;
  max-width: 592px; }
  .l-circlelist > * {
    display: flex;
    justify-content: center;
    margin-bottom: 12px;
    width: 25%; }

.l-close {
  position: absolute;
  right: 32px;
  top: 32px;
  z-index: 1; }

.l-containerhorizontal {
  padding-left: calc(50% - 352px);
  padding-right: calc(50% - 352px); }
  .l-containerhorizontal .l-containerhorizontal-x-textcontainer {
    padding-bottom: 64px;
    padding-top: 64px; }

.l-containerhorizontal-large {
  padding-left: calc(50% - 384px);
  padding-right: calc(50% - 384px); }

.l-entercode {
  padding-bottom: 64px; }
  .l-entercode .l-entercode-x-button {
    margin-top: 32px; }
  .l-entercode .l-entercode-x-character {
    margin-bottom: 32px;
    margin-top: 120px;
    z-index: 1; }
  .l-entercode .l-entercode-x-error {
    margin-top: 16px;
    max-width: 640px; }
  .l-entercode .l-entercode-x-input {
    margin-top: 32px; }

.l-logout {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-bottom: 64px;
  position: relative;
  width: 100%; }
  .l-logout .l-logout-x-close {
    position: absolute;
    right: 32px;
    top: 32px; }
  .l-logout .l-logout-x-character {
    padding-bottom: 32px;
    padding-top: 120px;
    z-index: 1; }
  .l-logout .l-logout-x-text {
    text-align: center;
    padding-bottom: 32px; }

.l-modal {
  height: 100%;
  position: fixed;
  width: 100%;
  z-index: 4; }

.l-modalcontainer.l-modalcontainer-is-active {
  overflow: hidden; }

.l-modallist {
  box-sizing: border-box;
  padding: 48px 32px 0; }
  .l-modallist > * {
    margin-bottom: 40px; }

.l-paddingbottom {
  padding-bottom: 8px; }

.l-paddingbottom-large {
  padding: 88px; }

.l-passcode {
  display: flex;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
  width: 356px; }
  .l-passcode > * {
    display: flex;
    justify-content: center;
    margin-bottom: 38px;
    width: 33.33%; }

.l-playlistitem {
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid #e1e0e1;
  display: flex;
  justify-content: flex-start;
  padding: 34px 32px 34px 32px;
  position: relative;
  z-index: 1; }
  .l-playlistitem .l-playlistitem-x-playstack,
  .l-playlistitem .l-playlistitem-x-favorite {
    margin-left: 32px; }
  .l-playlistitem .l-playlistitem-x-image {
    flex-shrink: 0; }
  .l-playlistitem .l-playlistitem-x-playstack {
    align-self: flex-end;
    flex-grow: 1; }
  .l-playlistitem .l-playlistitem-x-number {
    margin-right: 16px; }
  .l-playlistitem .l-playlistitem-x-title {
    margin-bottom: 16px;
    max-width: 100%;
    text-align: center; }
  .l-playlistitem h3 {
    margin-bottom: 16px; }

.l-playlistnumber {
  display: flex;
  justify-content: center;
  width: 64px; }

.l-text p {
  padding-bottom: 40px; }

.l-twocol {
  align-items: flex-start;
  box-sizing: border-box;
  display: flex; }
  .l-twocol > * {
    box-sizing: border-box;
    width: 50%; }
  .l-twocol .l-twocol-x-grow {
    flex-grow: 1; }
  .l-twocol .l-twocol-x-noshrink {
    flex-shrink: 0; }

.l-twocol-medium {
  width: 752px; }

.l-twocol-fluid > * {
  width: auto; }

.l-emptymessage {
  margin: 0 auto;
  max-width: 544px;
  padding-bottom: 64px;
  padding-top: 64px; }

.l-iconheader {
  padding-bottom: 14px;
  padding-top: 24px; }

.l-iconheader-paddingbottom-none {
  padding-bottom: 0; }

.l-logo {
  display: flex;
  justify-content: center;
  padding-top: 16px; }

.l-logoutlink {
  position: absolute;
  left: 32px;
  top: 32px; }

.l-textblock {
  padding-bottom: 60px; }
  .l-textblock h3 + p,
  .l-textblock h4 + p {
    margin-top: 0; }
  .l-textblock h1:not(:first-child),
  .l-textblock h2:not(:first-child) {
    margin-top: 32px; }
  .l-textblock h3:not(:first-child) {
    margin-top: 48px; }
  .l-textblock h4:not(:first-child) {
    margin-top: 16px; }
  .l-textblock h3 {
    font-family: Arial Black;
    font-size: 32px;
    line-height: 40px; }
    .l-textblock h3 + p {
      margin-top: 16px; }
    .l-textblock h3 + .textblock-x-stack {
      margin-top: 16px; }
  .l-textblock h4 {
    font-family: Arial Black;
    font-size: 24px;
    line-height: 32px; }
  .l-textblock a,
  .l-textblock p {
    margin-top: 8px; }
  .l-textblock ol,
  .l-textblock ul {
    margin-top: 16px;
    padding-left: 26px; }
    .l-textblock ol + h4:not(:first-child),
    .l-textblock ul + h4:not(:first-child) {
      margin-top: 32px; }
  .l-textblock li {
    padding-left: 2px; }
  .l-textblock:last-of-type {
    margin-bottom: 0; }

.l-textblock-x-listoflinks {
  display: flex;
  flex-direction: column; }
  .l-textblock-x-listoflinks a {
    margin-top: 16px; }

.l-textblock-narrow {
  padding: 0 82px; }

.l-navigation {
  align-items: stretch;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 16px 16px 0; }
  .l-navigation .l-navigation-x-avatar,
  .l-navigation .l-navigation-x-logout {
    flex: 1 1 100%; }
  .l-navigation .l-navigation-x-avatar {
    display: flex;
    justify-content: flex-end; }
  .l-navigation .l-navigation-x-items {
    align-items: center;
    display: flex;
    justify-content: space-around; }
  .l-navigation .l-navigation-x-logo {
    display: flex;
    justify-content: center; }
  .l-navigation .l-navigation-x-navitems {
    display: flex;
    flex: 0 0 auto;
    justify-content: center;
    position: relative; }
    .l-navigation .l-navigation-x-navitems > *:not(:first-child) {
      margin-left: 1px; }
    .l-navigation .l-navigation-x-navitems::after {
      background-image: url("images/buttonframe-mainnav-shadow.svg");
      background-position: 0 100%;
      background-repeat: no-repeat;
      background-size: 100%;
      bottom: 0;
      content: '';
      height: 100%;
      left: 50%;
      position: absolute;
      transform: translateX(-244px);
      width: 482px;
      z-index: -1; }

.l-navigationpadding {
  padding-top: 206px; }

.l-notebook {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 32px; }
  .l-notebook .l-notebook-x-audio {
    bottom: 16px;
    left: 16px;
    position: absolute; }
  .l-notebook .l-notebook-x-audio2xpadding {
    bottom: 32px;
    left: 32px;
    position: absolute; }
  .l-notebook .l-notebook-x-detail {
    height: 436px; }
  .l-notebook .l-notebook-x-noentries {
    margin-top: 166px; }
  .l-notebook .l-notebook-x-notebook {
    position: relative; }

.l-notebook-small {
  margin-bottom: 48px; }

.l-notebookitems {
  align-items: center;
  display: flex;
  justify-content: center; }
  .l-notebookitems > button:first-of-type {
    margin-right: 20px; }
  .l-notebookitems > :last-child {
    margin-left: 20px; }
  .l-notebookitems .l-notebookitems-x-scrollsection {
    width: 424px;
    overflow: hidden; }
    .l-notebookitems .l-notebookitems-x-scrollsection > div {
      display: flex; }
      .l-notebookitems .l-notebookitems-x-scrollsection > div > * {
        margin-right: 8px;
        flex: 0 0 98px; }

.l-notebookitems-short {
  width: 100%; }
  .l-notebookitems-short > button:first-of-type {
    margin-right: 45px; }
  .l-notebookitems-short > :last-child {
    margin-left: 45px; }
  .l-notebookitems-short .l-notebookitems-x-scrollsection {
    flex-grow: 1;
    overflow: hidden;
    width: 310px; }
    .l-notebookitems-short .l-notebookitems-x-scrollsection > div {
      display: flex;
      justify-content: center; }
      .l-notebookitems-short .l-notebookitems-x-scrollsection > div > * {
        margin-right: 8px;
        flex: 0 0 98px; }

.l-themepreviousclose {
  position: relative; }
  .l-themepreviousclose .themepreviousclose-x-close {
    left: 24px;
    position: absolute;
    top: 24px; }
  .l-themepreviousclose .themepreviousclose-x-previous {
    position: absolute;
    right: 24px;
    top: 24px; }

.l-themeselection {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-bottom: 64px; }
  .l-themeselection .l-themeselection-x-character {
    padding-top: 120px;
    z-index: 1; }
  .l-themeselection .l-themeselection-x-gobutton {
    padding-top: 16px;
    width: 258px; }
  .l-themeselection .l-themeselection-x-text {
    padding-bottom: 14px;
    padding-top: 32px;
    text-align: center; }

.l-flex {
  align-items: flex-start;
  display: flex; }
  .l-flex .l-flex-x-grow {
    flex-grow: 1; }
  .l-flex .l-flex-x-shrink {
    flex-shrink: 1; }
  .l-flex .l-flex-x-auto {
    flex: 0 0 auto; }
  .l-flex .l-flex-x-alignstart {
    align-self: flex-start; }

.l-flex-space0p5x > *:not(:last-child) {
  margin-right: 4px; }

.l-flex-space0p5x.l-flex-column > *:not(:last-child) {
  margin-bottom: 4px;
  margin-right: 0; }

.l-flex-space1x > *:not(:last-child) {
  margin-right: 8px; }

.l-flex-space1x.l-flex-column > *:not(:last-child) {
  margin-bottom: 8px;
  margin-right: 0; }

.l-flex-space1p5x > *:not(:last-child) {
  margin-right: 12px; }

.l-flex-space1p5x.l-flex-column > *:not(:last-child) {
  margin-bottom: 12px;
  margin-right: 0; }

.l-flex-space2x > *:not(:last-child) {
  margin-right: 16px; }

.l-flex-space2x.l-flex-column > *:not(:last-child) {
  margin-bottom: 16px;
  margin-right: 0; }

.l-flex-space2p5x > *:not(:last-child) {
  margin-right: 20px; }

.l-flex-space2p5x.l-flex-column > *:not(:last-child) {
  margin-bottom: 20px;
  margin-right: 0; }

.l-flex-space3x > *:not(:last-child) {
  margin-right: 24px; }

.l-flex-space3x.l-flex-column > *:not(:last-child) {
  margin-bottom: 24px;
  margin-right: 0; }

.l-flex-space3p5x > *:not(:last-child) {
  margin-right: 28px; }

.l-flex-space3p5x.l-flex-column > *:not(:last-child) {
  margin-bottom: 28px;
  margin-right: 0; }

.l-flex-space4x > *:not(:last-child) {
  margin-right: 32px; }

.l-flex-space4x.l-flex-column > *:not(:last-child) {
  margin-bottom: 32px;
  margin-right: 0; }

.l-flex-space4p5x > *:not(:last-child) {
  margin-right: 36px; }

.l-flex-space4p5x.l-flex-column > *:not(:last-child) {
  margin-bottom: 36px;
  margin-right: 0; }

.l-flex-space5x > *:not(:last-child) {
  margin-right: 40px; }

.l-flex-space5x.l-flex-column > *:not(:last-child) {
  margin-bottom: 40px;
  margin-right: 0; }

.l-flex-space5p5x > *:not(:last-child) {
  margin-right: 44px; }

.l-flex-space5p5x.l-flex-column > *:not(:last-child) {
  margin-bottom: 44px;
  margin-right: 0; }

.l-flex-space6x > *:not(:last-child) {
  margin-right: 48px; }

.l-flex-space6x.l-flex-column > *:not(:last-child) {
  margin-bottom: 48px;
  margin-right: 0; }

.l-flex-space6p5x > *:not(:last-child) {
  margin-right: 52px; }

.l-flex-space6p5x.l-flex-column > *:not(:last-child) {
  margin-bottom: 52px;
  margin-right: 0; }

.l-flex-space7x > *:not(:last-child) {
  margin-right: 56px; }

.l-flex-space7x.l-flex-column > *:not(:last-child) {
  margin-bottom: 56px;
  margin-right: 0; }

.l-flex-space7p5x > *:not(:last-child) {
  margin-right: 60px; }

.l-flex-space7p5x.l-flex-column > *:not(:last-child) {
  margin-bottom: 60px;
  margin-right: 0; }

.l-flex-space8x > *:not(:last-child) {
  margin-right: 64px; }

.l-flex-space8x.l-flex-column > *:not(:last-child) {
  margin-bottom: 64px;
  margin-right: 0; }

.l-flex-space8p5x > *:not(:last-child) {
  margin-right: 68px; }

.l-flex-space8p5x.l-flex-column > *:not(:last-child) {
  margin-bottom: 68px;
  margin-right: 0; }

.l-flex-space9x > *:not(:last-child) {
  margin-right: 72px; }

.l-flex-space9x.l-flex-column > *:not(:last-child) {
  margin-bottom: 72px;
  margin-right: 0; }

.l-flex-space9p5x > *:not(:last-child) {
  margin-right: 76px; }

.l-flex-space9p5x.l-flex-column > *:not(:last-child) {
  margin-bottom: 76px;
  margin-right: 0; }

.l-flex-space10x > *:not(:last-child) {
  margin-right: 80px; }

.l-flex-space10x.l-flex-column > *:not(:last-child) {
  margin-bottom: 80px;
  margin-right: 0; }

.l-flex-space10p5x > *:not(:last-child) {
  margin-right: 84px; }

.l-flex-space10p5x.l-flex-column > *:not(:last-child) {
  margin-bottom: 84px;
  margin-right: 0; }

.l-flex-space11x > *:not(:last-child) {
  margin-right: 88px; }

.l-flex-space11x.l-flex-column > *:not(:last-child) {
  margin-bottom: 88px;
  margin-right: 0; }

.l-flex-space11p5x > *:not(:last-child) {
  margin-right: 92px; }

.l-flex-space11p5x.l-flex-column > *:not(:last-child) {
  margin-bottom: 92px;
  margin-right: 0; }

.l-flex-space12x > *:not(:last-child) {
  margin-right: 96px; }

.l-flex-space12x.l-flex-column > *:not(:last-child) {
  margin-bottom: 96px;
  margin-right: 0; }

.l-flex-space12p5x > *:not(:last-child) {
  margin-right: 100px; }

.l-flex-space12p5x.l-flex-column > *:not(:last-child) {
  margin-bottom: 100px;
  margin-right: 0; }

.l-flex-space13x > *:not(:last-child) {
  margin-right: 104px; }

.l-flex-space13x.l-flex-column > *:not(:last-child) {
  margin-bottom: 104px;
  margin-right: 0; }

.l-flex-space13p5x > *:not(:last-child) {
  margin-right: 108px; }

.l-flex-space13p5x.l-flex-column > *:not(:last-child) {
  margin-bottom: 108px;
  margin-right: 0; }

.l-flex-space14x > *:not(:last-child) {
  margin-right: 112px; }

.l-flex-space14x.l-flex-column > *:not(:last-child) {
  margin-bottom: 112px;
  margin-right: 0; }

.l-flex-space14p5x > *:not(:last-child) {
  margin-right: 116px; }

.l-flex-space14p5x.l-flex-column > *:not(:last-child) {
  margin-bottom: 116px;
  margin-right: 0; }

.l-flex-space15x > *:not(:last-child) {
  margin-right: 120px; }

.l-flex-space15x.l-flex-column > *:not(:last-child) {
  margin-bottom: 120px;
  margin-right: 0; }

.l-flex-space15p5x > *:not(:last-child) {
  margin-right: 124px; }

.l-flex-space15p5x.l-flex-column > *:not(:last-child) {
  margin-bottom: 124px;
  margin-right: 0; }

.l-flex-space16x > *:not(:last-child) {
  margin-right: 128px; }

.l-flex-space16x.l-flex-column > *:not(:last-child) {
  margin-bottom: 128px;
  margin-right: 0; }

.l-flex-space16p5x > *:not(:last-child) {
  margin-right: 132px; }

.l-flex-space16p5x.l-flex-column > *:not(:last-child) {
  margin-bottom: 132px;
  margin-right: 0; }

.l-flex-space17x > *:not(:last-child) {
  margin-right: 136px; }

.l-flex-space17x.l-flex-column > *:not(:last-child) {
  margin-bottom: 136px;
  margin-right: 0; }

.l-flex-space17p5x > *:not(:last-child) {
  margin-right: 140px; }

.l-flex-space17p5x.l-flex-column > *:not(:last-child) {
  margin-bottom: 140px;
  margin-right: 0; }

.l-flex-space18x > *:not(:last-child) {
  margin-right: 144px; }

.l-flex-space18x.l-flex-column > *:not(:last-child) {
  margin-bottom: 144px;
  margin-right: 0; }

.l-flex-space18p5x > *:not(:last-child) {
  margin-right: 148px; }

.l-flex-space18p5x.l-flex-column > *:not(:last-child) {
  margin-bottom: 148px;
  margin-right: 0; }

.l-flex-space19x > *:not(:last-child) {
  margin-right: 152px; }

.l-flex-space19x.l-flex-column > *:not(:last-child) {
  margin-bottom: 152px;
  margin-right: 0; }

.l-flex-space19p5x > *:not(:last-child) {
  margin-right: 156px; }

.l-flex-space19p5x.l-flex-column > *:not(:last-child) {
  margin-bottom: 156px;
  margin-right: 0; }

.l-flex-space20x > *:not(:last-child) {
  margin-right: 160px; }

.l-flex-space20x.l-flex-column > *:not(:last-child) {
  margin-bottom: 160px;
  margin-right: 0; }

.l-flex-aligncenter {
  align-items: center; }

.l-flex-alignend {
  align-items: flex-end; }

.l-flex-alignstretch {
  align-items: stretch; }

.l-flex-column {
  flex-direction: column; }

.l-flex-justifyaround {
  justify-content: space-around; }

.l-flex-justifybetween {
  justify-content: space-between; }

.l-flex-justifycenter {
  justify-content: center; }

.l-flex-justifyend {
  justify-content: flex-end; }

.l-scrollbox {
  overflow: auto;
  visibility: hidden; }
  .l-scrollbox .l-scrollbox-x-content {
    visibility: visible; }
  .l-scrollbox:hover, .l-scrollbox:focus {
    visibility: visible; }
